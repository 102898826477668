import {
    CompositeFilterDisplayMode,
    CompositeFilterProps,
    FilmListEvents,
    FilmListProps,
    FilmThumbnailMediaType,
    SingleFilterProps,
} from '@vista/omnichannel-components-ui'
import { FilmFilterKey, FilmListPropsFromBe, SingleFilterDisplayMode } from './../../types'
import { FilterType, NestedPartial } from '@vista/omnichannel-components-domain'
import { UrlFactory, createAttributeQueryString } from '../../utils'

import { Film } from '@vista-digital/ocapi-types/v1'
import { defaultFilmListAssets } from '@vista/omnichannel-components-ui/lib/src/lib/components/film-list/types/assets'
import { defaultFilmListCdnOptions } from '@vista/omnichannel-components-ui/lib/src/lib/components/film-list/types/cdn-options'
import { defaultSingleFilterAssets } from '@vista/omnichannel-components-ui/lib/src/lib/components/single-filter/types/assets'
import { defaultSingleFilterCdnOptions } from '@vista/omnichannel-components-ui/lib/src/lib/components/single-filter/types/cdn-options'

export function filmListProps(props: FilmListPropsFromBe): FilmListProps {
    const events: FilmListEvents = {
        onFilmClicked: (film: Film) => {
            const filterId = props.config.filter.filterId

            const queryParamString = createAttributeQueryString(filterId)

            window.location.assign(new UrlFactory(props.workflow).getFilmDetailsUrl(film) + queryParamString)
        },
    }
    const cdnOptions = defaultFilmListCdnOptions
    cdnOptions.filmThumbnailImage = { width: 500 }
    return {
        displayText: props.displayText,
        config: filmListConfig(props),
        events,
        assets: {
            ...defaultFilmListAssets,
            noFilmsIconName: 'film-strip',
            noFilmsForFilterOptionIconName: 'film-strip',
            noFilmsForMultipleFiltersIconName: 'film-strip',
            failedToLoadDataIconName: 'curzon-warning',
        },
        cdnOptions: cdnOptions,
    }
}

function filmListConfig(props: FilmListPropsFromBe): FilmListProps['config'] {
    const carousel: FilmListProps['config']['carousel'] = {
        showDots: false,
        refreshOnUpdate: true,
        touchSettings: {
            draggable: true,
        },
        settings: {
            draggable: true,
            responsive: [
                {
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1.2,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3.2,
                    },
                },
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 4,
                    },
                },
            ],
        },
    }

    const config: FilmListProps['config'] = {
        componentId: props.config.filter.filterId,
        showWatchlistButton: false,
        mode: props.config.mode,
        structuredData: props.config.structuredData,
        mediaType: FilmThumbnailMediaType.Still,
        filter: {
            filterId: props.config.filter.filterId,
            options: props.config.filter.options,
        },
        carousel,
        filmUrlFactory: (film: Film) => new UrlFactory(props.workflow).getFilmDetailsUrl(film),
        playTrailerButton: {
            show: false,
            showControls: true,
            autoPlay: true,
            muted: true,
            loop: true,
        },
    }
    return config
}

export function filterProps(props: FilmListPropsFromBe): Omit<SingleFilterProps, 'displayText'> {
    return {
        filterId: props.config.filter.filterId,
        config: {
            filterType: props.config.filter.filterType,
            filter: FilmFilterKey.Category,
            displayMode: SingleFilterDisplayMode.PrimaryTab,
            enableSearch: false,
            showAllOption: true,
        },
        assets: defaultSingleFilterAssets,
        cdnOptions: defaultSingleFilterCdnOptions,
    }
}
export function compositeFilterProps(props: NestedPartial<FilmListPropsFromBe>): CompositeFilterProps {
    const { filterId } = props.config.filter
    const config: CompositeFilterProps['config'] = {
        displayMode: CompositeFilterDisplayMode.Modal,
        isFilterModalAnchoredToTop: false,
        filterType: FilterType.Film,
        filters: {
            [FilmFilterKey.Genre]: {
                enabled: true,
                index: 0,
                isMultiselect: true,
            },
            [FilmFilterKey.Category]: {
                enabled: false,
                index: 1,
                isMultiselect: true,
            },
            [FilmFilterKey.Event]: {
                enabled: false,
                index: 2,
                isMultiselect: true,
            },
            [FilmFilterKey.Attribute]: {
                enabled: true,
                index: 3,
                isMultiselect: true,
            },
        },
    }
    const displayText: CompositeFilterProps['displayText'] = {
        filterTitleLabel: 'Filter',
        genreFilterLabel: 'Genre',
        attributeFilterLabel: 'Experience',
        categoryFilterLabel: 'Category',
        eventFilterLabel: 'Event',
        billingPeriodFilterLabel: 'Billing period',
        billingRecurrenceFilterLabel: 'Billing type',
        subscriptionFilterLabel: 'Subscription',
        allFilterOptionLabel: 'All',
        modalTriggerButtonLabel: 'Filter by',
        accordionHeaderClearFiltersButtonLabel: 'Clear filters',
        modalCancelButtonLabel: 'Cancel',
        modalApplyButtonLabel: 'Apply',
        modalClearFiltersButtonLabel: 'Clear',
        selectFiltersMessage: 'Select filters to update results',
        resultCountMessage: '${count} film result(s) found',
    }
    const assets: CompositeFilterProps['assets'] = {
        filterModalButtonIconName: 'none',
    }
    const cdnOptions: CompositeFilterProps['cdnOptions'] = {
        attributeIcon: { height: 40 },
    }
    return { filterId, config, displayText, assets, cdnOptions }
}
