import { Dispatch, SetStateAction } from 'react'

import { Member } from '@vista-digital/ocapi-types/v1';
import { SignInStatus } from '../types'
import { member as m } from '.'

/**
 * Load and get member to set react states
 *
 * @param {Dispatch} setStatus state action
 * @param {Dispatch} setMember state action
 * @param {Function} loadMember function from utils that is getting member information.
 *
 * @example setMemberAsync(setStatus, setMember, loadMember)
 */
export async function setMemberAsync(
    setStatus: Dispatch<SetStateAction<SignInStatus>> | null,
    setMember: Dispatch<SetStateAction<Member>> | null,
    loadMember?: () => Promise<Member | undefined> | null
): Promise<void> {
    const member = loadMember ? await loadMember() : await m.load()
    const status = member ? SignInStatus.signedIn : SignInStatus.signedOut

    if (setStatus) setStatus(status)
    if (setMember) setMember(member)
}
