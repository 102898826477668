import { SwiperCarousel, SwiperOptions } from '../../../types'
import { swiperFacade, swiperState, showArrows } from '../swiperFacade'
import { slideBreakPoints } from './slideBreakPoints'

export const threeColCarousel = ({ config }: SwiperCarousel): void => {
    const { className, delay } = config
    document.querySelectorAll<HTMLElement>(className).forEach(element => {
        const { play } = swiperState(delay)
        const slidesCount = element.querySelectorAll('.swiper-slide').length
        const disableSwipeProperties =
            slidesCount < 4
                ? {
                      allowSlideNext: false,
                      allowSlidePrev: false,
                  }
                : {}

        let options: SwiperOptions = {
            ...play,
            observer: true,
            observeParents: true,
            loop: true,
            centeredSlides: false,
            slidesPerView: slidesCount === 1 ? 1 : 1.2,
            spaceBetween: 16,
            allowSlideNext: true,
            allowSlidePrev: true,
            breakpoints: {
                ...slideBreakPoints(3),
                768: {
                    ...slideBreakPoints(3)['768'],
                    ...disableSwipeProperties,
                },
                1200: {
                    ...slideBreakPoints(3)['1200'],
                    ...disableSwipeProperties,
                },
            },
        }

        options = { /*...stopSwiper(options), - just in case I need to re-enable it*/ ...options, autoplay: false }
        swiperFacade({ element, options, hideArrows: true })
        // swiper.init()

        if (slidesCount > 3) {
            showArrows(element)
        }
    })
}
