import {NotificationType, SeatDetails} from '@vista/omnichannel-components-domain'
import {
    OrderSeatPickerProps,
    SeatPickerLegendPosition,
    SeatPickerScreenPosition,
    SeatPickerShape,
} from '@vista/omnichannel-components-ui'
import {SeatAvailabilityStatus, SeatType} from '@vista-digital/ocapi-types/v1'

import {SeatPickerPropsFromBe} from './../../types'
import {addNotification} from './../../utils'

export const orderSeatPickerProps = ({ displayText }: SeatPickerPropsFromBe): OrderSeatPickerProps => ({
    displayText: {
        ...displayText,
        errorFailedToSaveTitle: 'Failed to save seat selection!',
        errorFailedToSaveMessage: 'Unfortunately you can only book 2 tickets online. To book for a larger group please call 08719 642838.',
    },
    assets: assets(),
    config: staticConfig(),
    events: events(),
})
const events = (): OrderSeatPickerProps['events'] => {
    return {
        onSeatClicked: async (seatDetails: SeatDetails) => {
            if (seatDetails.availabilityStatus !== SeatAvailabilityStatus.Available)
                addNotification(
                    { id: '1', messages: ['Seat is ' + seatDetails.availabilityStatus] },
                    NotificationType.Warning
                )
        },
    }
}

const assets = (): OrderSeatPickerProps['assets'] => ({
    failedToLoadDataIconName: 'curzon-warning',
    soldOutIconName: 'curzon-calendar',
})

const staticConfig = (): OrderSeatPickerProps['config'] => ({
    selectionRules: {
        cannotLeaveGapsBetweenSelectedSeats: {
            enabled: true,
            options: {
                allowGapsWithMultipleSeatsBetweenSelectedSeats: true,
            },
        },
        cannotLeaveSingleSeatGapFromAisle: {
            enabled: true,
            options: {
                ignoreSelectedSofaSeats: true,
                allowWhenAllSeatsBetweenTheSeatGapAndAnUnavailableSeatAreSelected: true,
            },
        },
        cannotLeaveSingleSeatGapFromUnavailableSeat: {
            enabled: true,
            options: {
                ignoreSelectedSofaSeats: true,
                allowWhenAllSeatsBetweenTheSeatGapAndAnUnavailableSeatAreSelected: true,
            },
        },
        mustFillSofaSeats: {
            enabled: false,
            options: {
                allowSinglePartialSofa: true,
            },
        },
        mustSelectAssociatedWheelchairSeat: {
            enabled: true,
        },
        mustRespectSeatSelectionLimits: {
            enabled: true,
        },
    },

    messageActionButton: {
        show: true,
    },

    screen: {
        bevelOffset: 30,
        height: 35,
        margin: 0,
        position: SeatPickerScreenPosition.Top,
    },

    seats: {
        appearance: {
            default: {
                iconName: 'normal-seat',
                showLabels: false,
                labelOffsetX: 0,
                labelOffsetY: 0,
            },
            normal: {
                default: {
                    iconName: 'normal-seat',
                },
                selected: {
                    iconName: 'normal-seat-selected',
                    showLabels: false,
                },
            },
            wheelchair: {
                default: {
                    iconName: 'wheelchair-seat',
                    showLabels: false,
                },
            },
            companion: {
                default: {
                    iconName: 'companion-seat',
                    showLabels: false,
                },
            },
            sofaLeft: {
                default: {
                    iconName: 'sofa-left-seat',
                    labelOffsetX: 3,
                    labelOffsetY: -3,
                },
                selected: {
                    iconName: 'sofa-left-seat-selected',
                    showLabels: false,
                },
            },
            sofaMiddle: {
                default: {
                    iconName: 'sofa-middle-seat',
                    labelOffsetX: 0,
                    labelOffsetY: -3,
                },
                selected: {
                    iconName: 'sofa-middle-seat-selected',
                    showLabels: false,
                },
            },
            sofaRight: {
                default: {
                    iconName: 'sofa-right-seat',
                    labelOffsetX: -3,
                    labelOffsetY: -3,
                },
                selected: {
                    iconName: 'sofa-right-seat-selected',
                    showLabels: false,
                },
            },
            friend: {
                default: {
                    iconName: 'friend-seat',
                    showLabels: false,
                },
            },
        },
        minimumSize: 44,
        margin: 0,
        appearanceRules: [
            {
                id: 'royalbox-seat-rule',
                criteria: {
                    areaCategories: ['Royal Box'],
                }
            }
        ] as any, // eslint-disable-line
        allowSeatsInUnallocatedAreasToBeSelected: false,
        defaultAvailabilityStatus: SeatAvailabilityStatus.Sold,
        legacyMode: false,
    },
    rowLabels: {
        size: 25,
        margin: 8,
        shape: SeatPickerShape.Rectangle,
        borderRadius: 3,
        borderWidth: 0,
    },
    areaLabels: {
        show: false,
        margin: 10,
    },
    navigation: {
        showPanButtons: false,
        showZoomButtons: false,
    },
    legend: {
        position: SeatPickerLegendPosition.Top,
        items: [
            {
                label: 'Available',
                seatType: SeatType.Normal,
            },
            {
                label: 'Unavailable',
                seatType: SeatType.Normal,
                showAsUnavailable: true,
            },
            {
                label: 'Your Selection',
                seatType: SeatType.Normal,
                showAsSelected: true,
            },
            {
                label: 'Wheelchair',
                seatType: SeatType.Wheelchair,
            },
            {
                label: 'Companion',
                seatType: SeatType.Companion,
            },
            {
                label: 'House',
                seatType: SeatType.Normal,
                showAsHouse: true,
                showAsUnavailable: true,
            },
            {
                // Pullman seats only appear when royalbox seats are present
                appearanceRuleId: 'royalbox-seat-rule',
                label: 'Pullman seats',
                seatType:  SeatType.Normal,
                customClass: 'pullman-seats-icon'
            },
            {
                appearanceRuleId: 'royalbox-seat-rule',
                label: 'Royal Box (4 seats each)',
                seatType: SeatType.Normal,
                customIconName: 'c-icon_mayfair-seat'
            },
        ],
        seatSize: 25,
    },
    notification: {
        show: true,
        style: NotificationType.Warning,
        displayInline: false,
    },
})
