import {
    Accordion,
    AccordionSection,
    HeadingLevel,
    TicketRedemptionCodeFormComponent,
    TicketRedemptionCodeFormFieldComponent,
    TicketRedemptionCodeFormFieldName,
    TicketRedemptionCodeFormHandles,
} from '@vista/omnichannel-components-ui'
import { FormFieldPropsDisplayTextProps, cardProps, formFieldPropsDisplayText, formProps } from './props'
import React, { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import { deleteUndefinedProperties, member, wait } from '../../utils'

import { TicketRedemptionComponentPropsFromBe } from '../../types'
import { loadShowtimeById } from '@vista/omnichannel-components-domain'

export const TicketRedemptionComponent: React.FC<TicketRedemptionComponentPropsFromBe> = props => {
    const ref = useRef<TicketRedemptionCodeFormHandles>(null)

    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        setAsync(ref, setLoaded, props.showtimeId)
    },[])
    
    // useEffect(() => {
    //     if(member.isSignedIn() && loaded)
    //         wait(1000).then(autoRedemption)
    // }, [loaded])

    const formFieldDisplayText:FormFieldPropsDisplayTextProps = formFieldPropsDisplayText(props)


    return loaded && (
        <>
            <div className={props.className ?? ''}>
                <Accordion expandIconName="chevron-down" headingLevel={HeadingLevel.H4}>
                    <AccordionSection id="TicketRedemption" title="Add voucher or special tickets" expandedByDefault={false}>
                        <TicketRedemptionCodeFormComponent ref={ref} {...formProps(props)} >
                            <TicketRedemptionCodeFormFieldComponent name={TicketRedemptionCodeFormFieldName.Code} displayText={formFieldDisplayText.code} />
                            <TicketRedemptionCodeFormFieldComponent name={TicketRedemptionCodeFormFieldName.Type} displayText={formFieldDisplayText.type}/>
                            <TicketRedemptionCodeFormFieldComponent name={TicketRedemptionCodeFormFieldName.Pin} displayText={formFieldDisplayText.pin} />
                        </TicketRedemptionCodeFormComponent>
                        {/* @TODO enable it when implementation will finish what they have to finish
                        <TicketRedemptionCardPickerComponent {...cardProps(props)} />
                        */}
                    </AccordionSection>
                </Accordion>
            </div>
            {/* <TemporaryHideNotificationError /> */}
        </>
    )
}

// export const TemporaryHideNotificationError: React.FC = () => {
//     const [injectStyle, setInjectStyle] = useState<boolean>(true)
//     const css = '.v-notification--style-error {display: none}'
//     useEffect(()=> {
//         wait(60000).then(setInjectStyle.bind(null, false))
//     },[])
//     return injectStyle && <style>{css}</style>
    
// }

const setAsync = async (
    ref: MutableRefObject<TicketRedemptionCodeFormHandles>,
    set: Dispatch<SetStateAction<boolean>>,
    id: TicketRedemptionComponentPropsFromBe['showtimeId'],
): Promise<void> => {
    await loadShowtimeById(id)
    set(true)
}
