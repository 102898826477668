import {
    GtmMemberLoggedInLoader,
    abandonOrderLoader,
    heroCarousel,
    mainNavSearchLoader,
    notificationListLoader,
    quoteCarousel,
    tagRedirect,
    threeColCarousel,
    countdownLoader,
} from './../components/'
import { heroCarouselProps, quoteCarouselProps, threeColPanelProps, threeColProps } from './../__HARDCODED__/swiper'
import { setDevMode, vanillaAccordionInit, addVerticalUnits } from '../utils'

/**
 * Initialise components that are not in componentData array (BE bridge)
 */

export function nullaryComponents(): void {
    setDevMode()
    mainNavSearchLoader()
    notificationListLoader()
    tagRedirect()
    abandonOrderLoader()
    GtmMemberLoggedInLoader()
    vanillaAccordionInit()
    countdownLoader()
    addVerticalUnits()

    // Load the carousels in a timeout to load everything else first
    setTimeout(() => {
        const d = new Date()

        heroCarousel(heroCarouselProps)
        quoteCarousel(quoteCarouselProps)
        threeColCarousel(threeColProps)
        threeColCarousel(threeColPanelProps)

        const ms = new Date().getTime() - d.getTime()
        console.debug(`Nullary components in timeout  init took ${ms}ms`)
    }, 250)
}
