import React from 'react'
import {  GiftCardPaymentFormComponent, GiftCardPaymentFormFieldComponent, GiftCardPaymentFormFieldName } from '@vista/omnichannel-components-ui'
import { GiftcardPaymentPropsFromBe } from '../../types'
import { giftCardProps } from './props'

export const GiftcardPaymentComponent: React.FC<GiftcardPaymentPropsFromBe> = props => {
    return (
        <>
            <GiftCardPaymentFormComponent {...giftCardProps(props)}>
                <GiftCardPaymentFormFieldComponent name={GiftCardPaymentFormFieldName.CardNumber} />
            </GiftCardPaymentFormComponent>
        </>
    )
}
