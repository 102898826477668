import { Accordion, AccordionSection, OrderCartComponent } from '@vista/omnichannel-components-ui'

import { HeadingLevel } from '@vista/omnichannel-components-ui'
import React from 'react'
import { ReviewBasketPropsType } from '../../types'
import {
    ShowtimeSummaryComponent,
} from '../../components/'
import { orderCartProps } from './props'
import { useShowtimeContext } from '@vista/omnichannel-components-ui';

export const ReviewBasketComponent: React.FC<ReviewBasketPropsType> = props => {
    const { showtime } = useShowtimeContext();
    return <>{showtime && <BasketComponent {...props} />}</>
}

export const BasketComponent: React.FC<ReviewBasketPropsType> = props => {
    const { OrderCart, ShowtimeSummary } = props

    return (
        <div className='width--normal padding--add'>
            <Accordion headingLevel={HeadingLevel.H1} expandIconName="chevron-down">
                <AccordionSection id="1" title="Your Order" expandedByDefault={true}>
                    <ShowtimeSummaryComponent {...ShowtimeSummary} />
                    <OrderCartComponent {...orderCartProps(OrderCart)} />
                </AccordionSection>
            </Accordion>
        </div>
    )
}
