export function getQueryParameterByName(name: string, url?: string): string {
    if (!url) url = window.location.href
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&')
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url)
    if (!results) return null
    if (!results[2]) return ''
    const param = decodeURIComponent(results[2].replace(/\+/g, ' '))
    return param
}
