import { BookNowPropsFromBe, BookNowPropsType } from './../../types'
import {
    FilmThumbnailMediaType,
    QuickNavigatorAssets,
    QuickNavigatorConfig,
    QuickNavigatorDisplayText,
    QuickNavigatorEvents,
    QuickNavigatorHandles,
    QuickNavigatorSelections,
    defaultQuickNavigatorConfig,
} from '@vista/omnichannel-components-ui'

import { isSignedIn, UrlFactory } from '../../utils'
import { WorkflowData } from '../../initialise/types'

export function CreateQuickNavigatorProps(props: BookNowPropsFromBe, quickNavigatorRefCurrent: QuickNavigatorHandles): BookNowPropsType {
    return {
        displayText: displayText(props),
        config: config(props),
        events: events(props.workflow, quickNavigatorRefCurrent),
        assets: assets(),
        cdnOptions: cdnOptions(),
    }
}

function events(workflow: WorkflowData, quickNavigatorRefCurrent: QuickNavigatorHandles): QuickNavigatorEvents {
    const urlFactory = new UrlFactory(workflow)

    return {
        onActionButtonClicked: (selections: QuickNavigatorSelections) => {
            const selected = {
                siteOnly: selections.site && !selections.film,
                filmOnly: !selections.site && selections.film,
                siteAndFilmOnly: selections.site && selections.film && !selections.screeningDate,
                screeningDate: selections.site && selections.film && selections.screeningDate && !selections.showtime,
                all: selections.site && selections.film && selections.screeningDate && selections.showtime,
            }

            
            if (selected.siteOnly) {
                const url = urlFactory.getCinemaDetailsUrl(selections.site)
                window.location.assign(url)
            }
            if (selected.filmOnly) {
                const url = urlFactory.getFilmDetailsUrl(selections.film)
                window.location.assign(url)
            }
            if (selected.siteAndFilmOnly) {
                const url = urlFactory.getCinemaDetailsUrlByFilm(selections.site, selections.film)
                window.location.assign(url)
            }
            if (selected.screeningDate) {
                const url = urlFactory.getCinemaDetailsUrlByFilmAndDate(
                    selections.site,
                    selections.film,
                    selections.screeningDate
                )
                window.location.assign(url)
            }
            if (selected.all) {
                const showtime = selections.showtime
                const index:number = showtime.isAllocatedSeating || !isSignedIn() ? 0 : 1
                quickNavigatorRefCurrent?.addSelectedSiteToStore()
                const url = urlFactory.getSeatPickerDetailsUrl(selections.showtime.id, index)
                window.location.assign(url)
            }
        },
    }
}

function displayText(props: BookNowPropsFromBe): QuickNavigatorDisplayText {
    return {        
        actionButtonLabel: 'Search',
        failedToLoadFilmsErrorMessage: 'Failed to load films',
        failedToLoadFilmsModalErrorMessage: 'Refresh your browser to try loading films again',
        failedToLoadFilmsModalErrorTitle: 'Oops, something went wrong!',
        failedToLoadScreeningDatesErrorMessage: 'Failed to load screening dates',
        failedToLoadScreeningDatesModalErrorMessage: 'Refresh your browser to try loading dates again',
        failedToLoadScreeningDatesModalErrorTitle: 'Oops, something went wrong!',
        failedToLoadShowtimesErrorMessage: 'Failed to load showtimes',
        failedToLoadShowtimesModalErrorMessage: 'Refresh your browser to try loading times again',
        failedToLoadShowtimesModalErrorTitle: 'Oops, something went wrong!',
        failedToLoadSitesErrorMessage: 'Failed to load cinemas',
        failedToLoadSitesModalErrorMessage: 'Refresh your browser to try loading cinemas again',
        failedToLoadSitesModalErrorTitle: 'Oops, something went wrong!',
        filmDropdownLabel: 'Movies',
        filmDropdownPlaceholder: 'Select a movie',
        filmModalHeading: 'Select a movie',
        filmModalSearchInputPlaceholder: 'Search movies',
        headingLabel: 'Quick Navigator',
        noFilmSearchResultsFoundModalMessage: 'Sorry, we couldn\'t find any films matching "${searchTerm}".',
        noFilmsForSiteModalErrorMessage: 'There are no films currently showing, please choose another cinema',
        noFilmsForSiteModalErrorTitle: 'Oops, nothing here!',
        noFilmsFoundErrorMessage: 'No films found',
        noFilmsModalErrorMessage: 'There are no films currently showing',
        noFilmsModalErrorTitle: 'Oops, nothing here!',
        noScreeningDateSearchResultsFoundModalMessage: 'Sorry, we couldn\'t find any dates matching "${searchTerm}".',
        noScreeningDatesFoundErrorMessage: 'No screening dates found',
        noScreeningDatesModalErrorMessage:
            'This film is not currently showing at this cinema, please choose another cinema',
        noScreeningDatesModalErrorTitle: 'Oops, nothing here!',
        noShowtimeSearchResultsFoundModalMessage: 'Sorry, we couldn\'t find any times matching "${searchTerm}".',
        noShowtimesFoundErrorMessage: 'No showtimes found',
        noSiteOrFilmSelectedErrorMessage: 'Please select a site or a film.',
        noSiteSearchResultsFoundModalMessage: 'Sorry, we couldn\'t find any cinemas matching "${searchTerm}".',
        noSitesFoundErrorMessage: 'No cinemas found',
        resetButtonLabel: 'Reset',
        screeningDateDropdownLabel: 'Date',
        screeningDateDropdownPlaceholder: 'Select a date',
        screeningDateFormat: '${date|d|ddd D MMMM}',
        screeningDateModalHeading: 'Select a date',
        screeningDateModalSearchInputPlaceholder: 'Search dates',
        showtimeDropdownLabel: 'Time',
        showtimeDropdownPlaceholder: 'Select a time',
        showtimeFormat: 'h:mm A',
        showtimeModalHeading: 'Select a time',
        showtimeModalSearchInputPlaceholder: 'Search times',
        siteDropdownLabel: 'Cinema',
        siteDropdownPlaceholder: 'Select a cinema',
        siteModalHeading: 'Select a cinema',
        siteModalSearchInputPlaceholder: 'Search cinemas',
        todayLabel: [' - ', { text: 'Today', className: 'v-screening-date-dropdown-option__today-label' }],
        ...props.displayText,
    }
}

function assets(): QuickNavigatorAssets {
    return {
        closeButtonIconName: 'c-icon_cross-black',
        failedToLoadFilmsModalErrorIconName: 'curzon-warning',
        failedToLoadScreeningDatesModalErrorIconName: 'curzon-warning',
        failedToLoadShowtimesModalErrorIconName: 'curzon-warning',
        failedToLoadSitesModalErrorIconName: 'curzon-warning',
        loadingDataIconName: 'spinner',
        noFilmsForSiteModalErrorIconName: 'film-strip',
        noFilmsFoundModalIconName: 'film-strip',
        noFilmsModalErrorIconName: 'film-strip',
        noScreeningDatesFoundModalIconName: 'curzon-calendar',
        noScreeningDatesModalErrorIconName: 'curzon-calendar',
        noShowtimesFoundModalIconName: 'curzon-calendar',
        noSitesFoundModalIconName: 'no-site-selected',
    }
}

function cdnOptions() {
    return {
        filmThumbnailImage: { width: 40 },
        censorRatingIcon: { height: 30 },
    }
}

function config(props: BookNowPropsFromBe): QuickNavigatorConfig {
    const enableSearch = true
    const displayAsModalOnMobile = false
    const showScreenName = true

    const withDefaults = {
        ...defaultQuickNavigatorConfig,
        ...props.config,
    }
    return {
        ...withDefaults,
        sites: {
            ...withDefaults.sites,
            enableSearch,
            displayAsModalOnMobile,
        },
        films: {
            ...withDefaults.films,
            enableSearch,
            displayAsModalOnMobile,
            mediaType: FilmThumbnailMediaType.Still,
        },
        screeningDates: {
            ...withDefaults.screeningDates,
            enableSearch,
            displayAsModalOnMobile,
        },
        showtimes: {
            ...withDefaults.showtimes,
            enableSearch,
            displayAsModalOnMobile,
            showScreenName,
        },
    }
}
