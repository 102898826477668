
import React, { useEffect, useState } from "react"

export interface ToggleProps {
    className?:string
    baseClass?:string
    label?:string
    isChecked?:boolean
    onClick?: Function
    isSwitchBeforeLabel?:boolean
}

export const Toggle = (props:ToggleProps) => {
    const {className, label, isChecked, onClick, baseClass, isSwitchBeforeLabel} = props

    const b = baseClass && baseClass.length ? baseClass + "__" : ''

    const [checked, setChecked] = useState(false);
  
    useEffect(()=>{
        setChecked(isChecked);
    }, [isChecked]);

    const onChangeHandler = () => {
        if(onClick) onClick()
        else setChecked(!checked)
    }

    const switchPositionClass = isSwitchBeforeLabel ? "inverse" : ""

    return (
        <div className={`${b}form-field form-field ${className || ""} ${switchPositionClass}`}>
            {label && label.length && !isSwitchBeforeLabel && <div className={`${b}form-label form-label`}>{label}</div>}
            <label className="switch">
                <input type="checkbox" checked={checked} onChange={onChangeHandler} />
                <span className="slider"></span>
            </label>
            {label && label.length && isSwitchBeforeLabel && <div className={`${b}form-label form-label`}>{label}</div>}
        </div>
    )
}