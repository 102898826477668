import { Accordion, AccordionSection, HeadingLevel } from '@vista/omnichannel-components-ui'

import { ContentAccordionPropsType } from '../../types'
import { v4 as uuidv4 } from 'uuid'

export const ContentAccordionComponent: React.FC<ContentAccordionPropsType> = props => {
    const { displayText } = props

    return (
        <>
            { displayText.text &&
                <div className="content-accordion__text" dangerouslySetInnerHTML={{ __html: displayText.text }}></div>
            }

            <Accordion headingLevel={HeadingLevel.H4} expandIconName="c-icon_curzon-chevron-down">
                {displayText.accordions.map(section =>
                    <AccordionSection key={uuidv4()} id={uuidv4()} title={section.heading} expandedByDefault={false}><div className="content-accordion__body" dangerouslySetInnerHTML={{ __html: section.body }}></div></AccordionSection>)}
            </Accordion>
        </>
    )
}

export default ContentAccordionComponent