import { ShowtimeSummaryComponent as OccShowtimeSummaryComponent } from '@vista/omnichannel-components-ui'
import React from 'react'
import { ShowtimeSummaryPropsPropsFromBe } from '../../types'
import { showtimeSummaryProps } from './props'

export const ShowtimeSummaryComponent: React.FC<ShowtimeSummaryPropsPropsFromBe> = props => {
    const className = props.className ?? ''
    const showtimeSummaryComponentProps = showtimeSummaryProps(props)

    /*@ts-ignore*/
    delete showtimeSummaryComponentProps.className

    /*@ts-ignore*/
    delete showtimeSummaryComponentProps.config.showFilmPoster

    /*@ts-ignore*/
    delete showtimeSummaryComponentProps.workflow

    
    return (
        <div className={className}>
            <OccShowtimeSummaryComponent {...showtimeSummaryComponentProps} />
        </div>
    )
}
