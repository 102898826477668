import React, { useState } from 'react'
import { initReactComponent } from './../../utils'
import { MainNavSearchProps } from './../../types'
import { Overlay } from './Overlay'

export const mainNavSearchLoader = (): void => {
    initReactComponent<MainNavSearchProps>({
        Component: props => <MainNavSearchComponent {...props} />,
        id: 'main-nav-search',
        props: {
            config: {
                tickMs: 750,
                minSearchLength: 3,
            },
        },
    })
}

function MainNavSearchComponent(props: MainNavSearchProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const closeOverlay = (): void => setIsOpen(false)
    const openOverlay = (): void => {
        setIsOpen(true)
    }

    const clsSvg = 'c-icon c-icon__magnifier c-icon__round--grey main-nav__extra__icons__search__icon'

    return (
        <>
            <svg className={clsSvg} onClick={openOverlay}>
                <use xlinkHref="#c-icon_magnifier"></use>
            </svg>
            <Overlay show={isOpen} close={closeOverlay} config={props.config} />
        </>
    )
}
