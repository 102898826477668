import React from 'react'
import { NotificationListComponent as OccNotificationListComponent } from '@vista/omnichannel-components-ui'
import { addNotification, getSessionStorage, initReactComponent, removeSessionStorage, SessionStorageNotification } from '../../utils'

export const notificationListLoader = (): void => {
    initReactComponent<any>({
        Component: () => <NotificationListComponent />,
        id: 'main-notification-list',
        props: {},
    })

    const notification = getSessionStorage<SessionStorageNotification>(SessionStorageNotification.key)
    if (notification) {
        addNotification(notification.details, notification.type)
        removeSessionStorage(SessionStorageNotification.key)
    }
}

export const NotificationListComponent: React.FC = () => {
    return (
        <>
            <OccNotificationListComponent />
        </>
    )
}
