interface newBreakpoints {
    768: {
        slidesPerView: number
        spaceBetween: number
    }
    1200: {
        slidesPerView: number
        spaceBetween: number
    }
}

export const slideBreakPoints = (numberOfSlides: number): newBreakpoints => {

    const slideCount = Math.min(numberOfSlides, 4)

    const breakPointMap = {
        1: {
            768: {
                slidesPerView: 1,
                spaceBetween: 16,
            },
            1200: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
        },
        2: {
            768: {
                slidesPerView: 2,
                spaceBetween: 16,
            },
            1200: {
                slidesPerView: 2,
                spaceBetween: 20,
            },
        },
        3: {
            768: {
                slidesPerView: 2.9,
                spaceBetween: 16,
            },
            1200: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
        4: {
            768: {
                slidesPerView: 3.2,
                spaceBetween: 16,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 20,
            },
        }
    }

    return breakPointMap[slideCount]
}
