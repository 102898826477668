import {
    FilmThumbnailMediaType,
    ShowtimeSummaryAssets,
    ShowtimeSummaryCdnOptions,
    ShowtimeSummaryDisplayText,
    ShowtimeSummaryProps,
} from '@vista/omnichannel-components-ui'

import { ShowtimeSummaryPropsPropsFromBe } from '../../types'

export const showtimeSummaryProps = (props: ShowtimeSummaryPropsPropsFromBe): ShowtimeSummaryProps => ({
    ...props,
    displayText: {
        ...defaultDisplayText(),
        ...props.displayText,
    },
    config: {
        ...defaultConfig(),
        ...props.config,
        showFilmBackdrop: !!props?.config?.showFilmPoster,
        attributeLegend: {
            show: true,
            showLabel: true,
        },
    },
    assets: defaultAssets(),
    cdnOptions: defaultCdnOptions(),
})

export const selectedSeatsProps = (props: ShowtimeSummaryPropsPropsFromBe): ShowtimeSummaryProps => {
    const baseProps = showtimeSummaryProps(props)
    return {
        ...baseProps,
        config: {
            ...baseProps.config,
            filmThumbnail: {
                show: false,
                mediaType: FilmThumbnailMediaType.Still,
            },
            showOrderTotal: false,
            showSeatsSummary: true,
            showFilmBackdrop: false,
            attributeLegend: {
                show: false,
            },
        },
    }
}

function defaultDisplayText(): ShowtimeSummaryDisplayText {
    return {
        siteNameLabel: 'Cinema',
        screenNameLabel: 'Screen',
        startsAtLabel: 'Date / Time',
        runtimeLabel: 'Runtime',
        selectedSeatsLabel: 'Seats',
        //startsAtFormat: '${startTime| HH:mm |d|MMMM DD, YYYY}', - seemed to cause an error
        startsAtFormat: '${startsAt|d|MMM DD, HH:mm}', //- this one defintely works
        attributeLegendLabel: 'Showtime Attributes',
        attributeLegendViewButtonLabel: 'What do these mean?',
        attributeLegendModalHelpText: undefined,
    }
}

function defaultConfig() {
    return {
        filmThumbnail: {
            show: true,
            mediaType: FilmThumbnailMediaType.Still,
        },
        showOrderTotal: true,
        showSeatsSummary: true,
        showFilmBackdrop: true,
        attributeLegend: {
            show: true,
        },
    }
}

function defaultAssets(): ShowtimeSummaryAssets {
    return {
        siteIconName: 'location-pin',
        startsAtIconName: 'clock',
        screenIconName: 'screen-wth-seats',
        runtimeIconName: 'clock-duration',
        seatIconName: 'seat',
    }
}

function defaultCdnOptions(): ShowtimeSummaryCdnOptions {
    return {
        filmThumbnailImage: { width: 500 },
        filmBackdropImage: { width: 1920 },
        censorRatingIcon: { height: 60 },
        attributeIcon: { height: 40 },
    }
}
