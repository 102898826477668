import { CompositeFilterComponent, FilmListComponent as OccFilmListComponent } from '@vista/omnichannel-components-ui'
import React, { useEffect, useMemo } from 'react'
import { compositeFilterProps, filmListProps, filterProps } from './props'
import { getFilms, getFilterByIdAndType } from '@vista/omnichannel-components-domain'

import { FilmListPropsFromBe } from './../../types'
import { SingleFilterComponent } from '@vista/omnichannel-components-ui'
import { observer } from 'mobx-react'

export const FilmListComponent: React.FC<FilmListPropsFromBe> = observer(props => {
    // This is to fix the issue from https://vistadigital.atlassian.net/browse/CRZN-1186
    const filter = getFilterByIdAndType(props.config.filter.filterId, props.config.filter.filterType)
    const isHomePage = window.location.pathname === '/';
    // This is to fix the issue from https://vistadigital.atlassian.net/browse/CRZN-1285
    const filteredFilms = getFilms(undefined, filter)

    useEffect(() => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
        }, 200)
    }, [filter, filter?.sections?.category?.options?.find(o => o.selected), filteredFilms])

    const singleFilterComponentProps = useMemo(() => ({ ...filterProps(props) }), [])
    const compositeFilterComponentProps = useMemo(() => ({ ...compositeFilterProps(props) }), [])
    const occFilmListComponentProps = useMemo(() => ({ ...filmListProps(props) }), [])
    return (
        <>
            <SingleFilterComponent {...singleFilterComponentProps} />
            { !isHomePage && <CompositeFilterComponent {...compositeFilterComponentProps} />}
            <OccFilmListComponent {...occFilmListComponentProps} />
        </>
    )
})

export default FilmListComponent
