import { showLog } from './../__HARDCODED__/dbg'

/* eslint-disable */
export const log = showLog ? console.log.bind(console) : () => {}
export const info = showLog ? console.info.bind(console) : () => {}
export const debug = showLog ? console.debug.bind(console) : () => {}
export const warn = showLog ? console.warn.bind(console) : () => {}
export const group = showLog ? console.group.bind(console) : () => {}
export const groupCollapsed = showLog ? console.groupCollapsed.bind(console) : () => {}
export const groupEnd = showLog ? console.groupEnd.bind(console) : () => {}
export const err = console.error.bind(console)

export const timeMark = showLog ? console.time.bind(console) : () => {}
export const timeMarkEnd = showLog ? console.timeEnd.bind(console) : () => {}

/* eslint-enable */
