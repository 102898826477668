import React, { useContext, useEffect, useState } from 'react'
import { MemberSubscriptionComponent } from '@vista/omnichannel-components-ui'
import { bannerProps } from './props'
import { MemberAccContext } from '../../context'
import { loadMember } from '../../utils'
import { useSubscriptionBackground } from './hooks/useSubscriptionBG';

export const MemberAccBannerComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)
    const [showBuyMembership, setShowBuyMembership] = useState(false)
    const { currentBackground, futureBackground } = useSubscriptionBackground();

    useEffect(() => {
        (async () => {
            const member = await loadMember()
            if (member?.subscriptions) return;

            if (ctx.MemberBannerLinks && ctx.MemberBannerLinks.buyMembershipLink) 
                setShowBuyMembership(true)
        })()
    }, [])

    const onRenewClick = () => {
        if (ctx.MemberBannerLinks && ctx.MemberBannerLinks.renewMembershipLink) {
            window.location.assign(ctx.MemberBannerLinks.renewMembershipLink.href)
        }
    }

    return (
        <>
            <MemberSubscriptionComponent {...bannerProps(ctx.MemberSubscription, () => onRenewClick())} />
            {showBuyMembership && (
                <div className="buy-membership">
                    <a className="buy-membership-btn" href={ctx.MemberBannerLinks.buyMembershipLink.href}>
                        {ctx.MemberBannerLinks.buyMembershipLink.text}
                    </a>
                </div>
            )}
            <style>
                {`
                 .v-member-subscription > *:nth-of-type(1) .v-member-subscription-base__header,
                 .v-member-subscription > *:nth-of-type(1) .v-tag-list {
                    background-color:${currentBackground};
                 }

                 .v-member-subscription > *:nth-of-type(2) .v-member-subscription-base__header,
                 .v-member-subscription > *:nth-of-type(2) .v-tag-list {
                    background-color:${futureBackground};
                 }
                `}
            </style>
        </>
    )
}
