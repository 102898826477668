import React, { ReactNode } from 'react'
import { MemberAccSidebarComponent } from './MemberAccSidebar'
import cls from 'classnames'

export const MemberAccPageComponent: React.FC<{ children: ReactNode; className?: string }> = props => {
    const clsMemberacc__content = cls('memberacc__content', {
        ['memberacc__' + props.className]: !!props.className,
        [props.className]: !!props.className,
    })
    return (
        <div className="memberacc">
            <MemberAccSidebarComponent className="memberacc__side" />
            <div className={clsMemberacc__content}>{props.children}</div>
        </div>
    )
}
