import { EmailMemberCredentialFormPropsFromBe,  PasswordMemberCredentialFormPropsFromBe } from '../../types'
import {
    FormEvents,
    HeadingLevel,
    Input,
    MemberFormDisplayText,
    UpdateMemberDetailsFormConfig,
    Modal,
    UpdateMemberDetailsFormComponent,
    UpdateMemberDetailsFormFieldComponent,
    UpdateMemberEmailFormComponent,
    UpdateMemberEmailFormFieldComponent,
    UpdateMemberEmailFormFieldName,
    UpdateMemberPasswordFormComponent,
    UpdateMemberPasswordFormFieldComponent,
    UpdateMemberPasswordFormFieldName,
} from '@vista/omnichannel-components-ui'
import { LoadCaptcha, log, member, resetCaptcha } from '../../utils'
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import {defaultUpdateMemberEmailFormCurrentPasswordFieldDisplayText, defaultUpdateMemberEmailFormEmailFieldDisplayText} from '@vista/omnichannel-components-ui/lib/src/lib/components/update-member-email-form-field/types/display-text'
import { memberPhotoUploaderProps, updateMemberFieldsProps } from './props'

import { MemberAccContext } from '../../context'
import { MemberPhotoUploader } from '@vista/omnichannel-components-ui/lib/src/lib/components/member/photo-uploader/member-photo-uploader'
import { NestedPartial } from '@vista/omnichannel-components-domain'
import { Title } from '../atoms'
import { defaultMemberFormDisplayText } from '@vista/omnichannel-components-ui/lib/src/lib/subcomponents/member-form/types/display-text'
import {defaultUpdateMemberPasswordFormPasswordFieldDisplayText} from '@vista/omnichannel-components-ui/lib/src/lib/components/update-member-password-form-field/types/display-text'

export const MemberAccDetailsComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)
    const { displayText } = ctx.MemberCustomPersonalDetails    
   
    const [memberEmail, setMemberEmail] = useState("")
    useEffect(() => {
        setMemberEmailAsync(setMemberEmail)
    },[])

    const [isOpenEmailModal, setIsOpenEmailModal] = useState(false)
    const openEmailModal = () => setIsOpenEmailModal(true)
    const closeEmailModal = () => setIsOpenEmailModal(false)

    const [isOpenPasswordModal, setIsOpenPasswordModal] = useState(false)
    const openPasswordModal = () => setIsOpenPasswordModal(true)
    const closePasswordModal = () => setIsOpenPasswordModal(false)


    const updateMemberDetailsFormComponentDisplayText = (props):MemberFormDisplayText => {
        
        return {
            formSubmittedMessage: props.formSubmittedMessage || defaultMemberFormDisplayText.formSubmittedMessage,
            errorFailedToSaveTitle: props.errorFailedToSaveTitle || defaultMemberFormDisplayText.errorFailedToSaveTitle,
            errorFailedToSaveMessage: props.errorFailedToSaveMessage || defaultMemberFormDisplayText.errorFailedToSaveMessage,
            submitButtonLabel: displayText.updateDetailsButtonLabel || defaultMemberFormDisplayText.submitButtonLabel, // This is from MemberCustomPersonalDetails
            cancelButtonLabel: props.cancelButtonLabel || defaultMemberFormDisplayText.cancelButtonLabel
        }
    }

    const UpdateMemberDetailsFormConfig:NestedPartial<UpdateMemberDetailsFormConfig> = {
        validation:{
            gender:{required:false}, 
            termsAndConditions:{required:false},
            suburb:{required:false},
            captcha:{required:false}
        }
    }

    return (
        <>
            <div className="update-member__wrap">
                <div className="update-member__details">
                    <h2 className="center title">{displayText.personalDetailsTitle ?? 'Personal details'}</h2>

                    <UpdateMemberDetailsFormComponent displayText={updateMemberDetailsFormComponentDisplayText(ctx.MemberDetailsForm)}  config={UpdateMemberDetailsFormConfig}>
                        <Title hx={3}>{displayText.memberDetailsSubheading ?? 'Member details'}</Title>
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).givenName} />
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).familyName} />
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).mobileNumber} />
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).homeNumber} />
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).dateOfBirth} />

                        <Title hx={3}>{displayText.addressDetailsSubheading ?? 'Address Details'}</Title>
                        <UpdateMemberDetailsFormFieldComponent name={updateMemberFieldsProps(ctx.MemberDetailsForm).address.name} displayText={updateMemberFieldsProps(ctx.MemberDetailsForm).address.displayText} />
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).suburb} />
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).city} />
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).state} />
                        <UpdateMemberDetailsFormFieldComponent {...updateMemberFieldsProps(ctx.MemberDetailsForm).postCode} />

                    </UpdateMemberDetailsFormComponent>
                  
                </div>
                <div className="update-member__cred">
                    <Title hx={3}>{displayText.accountDetailsTitle ?? 'Account details'}</Title>
                    <div className="input__email"> <Input value={memberEmail} disabled /> </div>
                    <button className="btn btn--black btn__email" onClick={openEmailModal}>CHANGE</button>
                    <div className="input__pwd"> <Input type='password' value='admin123' disabled /> </div>
                    <button className="btn btn--black btn__pwd" onClick={openPasswordModal}>CHANGE</button>
                </div>
                <div className="update-member__photo">
                    <Title hx={3}>Manage photo</Title>
                    <MemberPhotoUploader {...memberPhotoUploaderProps(ctx.MemberPhotoUploader)} />
                </div>
            </div>
            <UpdateEmailModal show={isOpenEmailModal} close={closeEmailModal} />
            <UpdatePasswordModal show={isOpenPasswordModal} close={closePasswordModal} />
        </>
    )
}

interface UpdateCredModal {
    show: boolean,
    close: VoidFunction
}

export const UpdateEmailModal: React.FC<UpdateCredModal> = props => {
    const ctx = useContext(MemberAccContext)

    const events: FormEvents = {            
        onAfterFailedSubmit: (e: Error) => {
            resetCaptcha()
            log('onAfterFailedSubmit', e)
        },
        onCancelButtonClicked: () => props.close()
    }
    
    return (
       
        <Modal show={props.show} onClose={props.close} heading='Update email address' headingLevel={HeadingLevel.H3}>
            <UpdateMemberEmailFormComponent events={events}>
                <UpdateMemberEmailFormFieldComponent displayText={getEmailDisplayText(ctx)} name={UpdateMemberEmailFormFieldName.Email} />
                <UpdateMemberEmailFormFieldComponent displayText={getCurrentPasswordDisplayText(ctx)} name={UpdateMemberEmailFormFieldName.CurrentPassword} />
                
                <LoadCaptcha>
                    <UpdateMemberEmailFormFieldComponent name={UpdateMemberEmailFormFieldName.Captcha} />
                </LoadCaptcha>                
            </UpdateMemberEmailFormComponent>
        </Modal>
    )
}

export const UpdatePasswordModal: React.FC<UpdateCredModal> = props => {
    const ctx = useContext(MemberAccContext)

    const events: FormEvents = {            
        onAfterFailedSubmit: (e: Error) => {
            resetCaptcha()
            log('onAfterFailedSubmit', e)
        },
        onCancelButtonClicked: () => props.close()
    }

    return (
        <Modal show={props.show} onClose={props.close} heading='Update password' headingLevel={HeadingLevel.H3}>
            <UpdateMemberPasswordFormComponent events={events}>
                <UpdateMemberPasswordFormFieldComponent displayText={getCurrentPasswordDisplayText(ctx)} name={UpdateMemberPasswordFormFieldName.CurrentPassword} />
                <UpdateMemberPasswordFormFieldComponent displayText={getNewPasswordDisplayText(ctx)} name={UpdateMemberPasswordFormFieldName.Password} />
                <LoadCaptcha>
                    <UpdateMemberPasswordFormFieldComponent name={UpdateMemberPasswordFormFieldName.Captcha} />
                </LoadCaptcha>
            </UpdateMemberPasswordFormComponent>

        </Modal>
    )
}

const getEmailDisplayText = (context) => {
    const emailDisplayText:NestedPartial<EmailMemberCredentialFormPropsFromBe['displayText']> = {
        ...context.EmailMemberCredentialForm.displayText
    }

    const emailFieldDisplayText = {
        label: emailDisplayText?.emailInputLabel || defaultUpdateMemberEmailFormEmailFieldDisplayText.label,
        requiredMessage: emailDisplayText?.errorCurrentPasswordRequiredMessage || defaultUpdateMemberEmailFormEmailFieldDisplayText.requiredMessage,
        formatMessage: emailDisplayText?.errorCurrentPasswordFormatMessage || defaultUpdateMemberEmailFormEmailFieldDisplayText.formatMessage,
        lengthMessage:  emailDisplayText?.errorCurrentPasswordLengthMessage || defaultUpdateMemberEmailFormEmailFieldDisplayText.lengthMessage,
    }

    return emailFieldDisplayText
}

const getCurrentPasswordDisplayText = (context) => {
    const passwordDisplayText:NestedPartial<PasswordMemberCredentialFormPropsFromBe['displayText']> = {
        ...context.EmailMemberCredentialForm.displayText
    }

    const passwordFieldDisplayText = {
        label: passwordDisplayText?.currentPasswordInputLabel || defaultUpdateMemberEmailFormCurrentPasswordFieldDisplayText.label,
        requiredMessage: passwordDisplayText?.errorCurrentPasswordRequiredMessage || defaultUpdateMemberEmailFormCurrentPasswordFieldDisplayText.requiredMessage,
        formatMessage: passwordDisplayText?.errorCurrentPasswordFormatMessage || defaultUpdateMemberEmailFormCurrentPasswordFieldDisplayText.formatMessage,
        lengthMessage: passwordDisplayText?.errorCurrentPasswordLengthMessage || defaultUpdateMemberEmailFormCurrentPasswordFieldDisplayText.lengthMessage,
        //placeholder: passwordDisplayText?.currentPasswordInputLabel || defaultUpdateMemberEmailFormCurrentPasswordFieldDisplayText.placeholder
    }

    return passwordFieldDisplayText
}

const getNewPasswordDisplayText = (context) => {
    const newPasswordDisplayText:NestedPartial<PasswordMemberCredentialFormPropsFromBe['displayText']> = {
        ...context.PasswordMemberCredentialForm.displayText
    }

    const newPasswordFieldDisplayText = {
        label: newPasswordDisplayText?.newPasswordInputLabel || defaultUpdateMemberPasswordFormPasswordFieldDisplayText.label,
        //placeholder: newPasswordDisplayText?.newPasswordInputLabel || defaultUpdateMemberPasswordFormPasswordFieldDisplayText.label,
    }

    return newPasswordFieldDisplayText
}


const setMemberEmailAsync = async (setMemberEmail:  Dispatch<SetStateAction<string>>): Promise<void> =>  {
    const email = await member.getEmail()
    setMemberEmail(email)
}

