import React, { useState, useEffect, useMemo, useRef } from 'react'
import { SearchOverlayProps, SearchRes } from './../../types'
import classnames from 'classnames'
import { getSearchRes } from './../../api'
import { NoResults } from './NoResults'
import { Categories } from './Categories'
import { Input } from '@vista/omnichannel-components-ui'
import { filterSearchResults } from './helpers'

export const Overlay: React.FC<SearchOverlayProps> = props => {
    const [searchRes, setSearchRes] = useState<SearchRes>({
        results: [],
        noResultsMessage: '',
    })
    const [value, setValue] = useState<string>('')
    const inputContainerRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        const action = props.show ? 'add' : 'remove'
        document.body.classList[action]('noscrollbar')
    })

    const debounce = useMemo(() => new Debounce(props.config.tickMs), [])

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        debounce.execute(() => setSearchResAsync({ setSearchRes, getSearchRes, props, e }))
        setValue(e.target.value)
    }

    const baseCls = 'search-overlay'
    const cls = {
        main: classnames(baseCls, 'width--full', {
            [`${baseCls}--open`]: props.show,
        }),
        wrap: baseCls + '__wrap',
        close: baseCls + '__close',
        input: baseCls + '__input',
        noresults: baseCls + '__noreults',
        categories: baseCls + '__categorys',
    }

    const mainCategories = ['Film', 'Event', 'Cinema', 'JournalArticle']
    const categoriesFromApi = searchRes.results.map(result => {
        return result.category
    })

    const combinedArray = [...mainCategories, ...categoriesFromApi]
    const categories = [...new Set(combinedArray)]

    const splitCategories = () => {
        return categories.map(category => {
            return (
                <Categories
                    className={cls.categories}
                    classBase={baseCls}
                    results={filterSearchResults(searchRes.results, category)}
                    categoryName={category}
                    key={category}
                />
            )
        })
    }

    useEffect(() => {
        if (inputContainerRef.current) {
            // Required code to show ios keyboard when programatically focusing input for ios devices
            inputContainerRef.current.style.display = 'block'
            const input = inputContainerRef.current.querySelector('input')
            input.focus()
        }
    }, [props.show])

    return (
        <div id="search-overlay" className={cls.main}>
            <span className={cls.close} onClick={props.close}>
                <svg className="v-icon v-icon__close">
                    <use xlinkHref="#c-icon_cross-black"></use>
                </svg>
            </span>
            <div className={cls.wrap}>
                <div className={cls.input} ref={inputContainerRef}>
                    <Input value={value} placeholder={'Search For a Film, Venue or Event'} onChange={onChangeHandler} />
                </div>
                {!searchRes.results.length && <NoResults className={cls.noresults} msg={searchRes.noResultsMessage} />}
                {splitCategories()}
            </div>
        </div>
    )
}

async function setSearchResAsync({ setSearchRes, getSearchRes, props, e }) {
    const _searchRes = await getSearchRes(e.target.value, props.config.minSearchLength)
    setSearchRes(_searchRes)
}

class Debounce {
    private intervalMs: number
    private timeout: NodeJS.Timeout = null

    constructor(ms: number) {
        this.intervalMs = ms
    }

    public execute = (func: () => void) => {
        if (this.timeout) clearTimeout(this.timeout)

        this.timeout = setTimeout(func, this.intervalMs)
    }
}
