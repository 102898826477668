import { OrderPaymentFormComponent as OccOrderPaymentFormComponent } from '@vista/omnichannel-components-ui'
import { OrderPaymentFormPropsFromBe } from '../../types'
import React from 'react'
import { orderPaymentFormProps } from './props'

export const OrderPaymentFormComponent: React.FC<OrderPaymentFormPropsFromBe> = props => {

    const orderPaymentProps = {
        ...orderPaymentFormProps(props), events: props.events
    }
    return <OccOrderPaymentFormComponent {...orderPaymentProps} />
}
