import React, { useContext } from 'react'

import { MemberAccContext } from '../../context'
import { MemberFilmWatchlistComponent } from '@vista/omnichannel-components-ui'
import { isMemberMenuLinkActive } from '../../utils'
import { watchlistProps } from './props'

export const MemberAccWatchlistComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)

    const { menuItems } = ctx.MemberAccountMenu.displayText

    const pageTitleIndex = menuItems.filter(link => isMemberMenuLinkActive(link.href))[0]

    return (
        <div className="member-watchlist">
            <h2 className="center page-title">{pageTitleIndex.text}</h2>
            <MemberFilmWatchlistComponent {...watchlistProps(ctx.Watchlist)} />
        </div>
    )
}
