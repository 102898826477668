import React, { useState } from 'react'

import { getSelectedSites } from '@vista/omnichannel-components-domain'
import { observer } from 'mobx-react'

interface CinemaTooltipProps {
    displayText: DisplayText
}

interface DisplayText {
    noOfDays: number
    tooltipText: string
}

const storageKey = 'cinema-tooltip-expiry'

export const CinemaTooltipComponent: React.FC<CinemaTooltipProps> = observer(props => {
    const [isVisible, setIsVisible] = useState(true)

    const overrideTooltip = (): void => {
        const date = dateWithAddedDays(props.displayText.noOfDays)
        localStorage.setItem(storageKey, date.toDateString())
        setIsVisible(false)
        document.querySelector('.main-nav').classList.remove('has-tooltip')
    }
    const dateWithAddedDays = (days: number): Date => {
        const result = new Date()
        result.setDate(result.getDate() + days)
        return result
    }

    const isSiteSelected = getSelectedSites().length > 0

    if (!props.displayText.tooltipText || isSiteSelected || !ShouldTooltipShow()) return null
    return (
        <div className="tooltip-container">
            <div className="tooltip-title">{props.displayText.tooltipText}</div>
            <button className="tooltip-close-button" onClick={overrideTooltip}>
                <svg className="global-notification-banner__icon">
                    <use xlinkHref={'#c-icon_cross-black'}></use>
                </svg>
            </button>
        </div>
    )
})

const ShouldTooltipShow = (): boolean => {
    const now = new Date()

    if (localStorage.getItem(storageKey)) {
        const overrideExpiryDate = new Date(localStorage.getItem(storageKey))
        const isExpired = now >= overrideExpiryDate

        if (isExpired) localStorage.removeItem(storageKey)

        return isExpired
    } else {
        return true
    }
}

export default CinemaTooltipComponent