import {

    MemberSignInFormComponent,
    MemberSignInFormFieldComponent,
    MemberSignInFormFieldName as Name,
    CaptchaHandles, MemberSignUpFormHandles,
} from '@vista/omnichannel-components-ui'
import { ISignIn} from '../../types'
import {getQueryStringVal, LoadCaptcha} from '../../utils'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { formFieldsDisplayText, formPropsConfig, formPropsDisplayText, formPropsEvents, loginCheckExpiryTimeInMs } from './props'
import {SignInFormFieldForgotPassword} from './'


declare global {
    interface Window {
        captchaRef: RefObject<CaptchaHandles>;
    }
}

export const SignIn: React.FC<ISignIn> = props => {
    window.captchaRef = useRef(null)
    const email = getQueryStringVal('email')
    const formRef = React.createRef<MemberSignUpFormHandles>()
    const [refreshKey, setRefreshKey] = useState<string>() // Need to refresh OCC so the displayText can switch properties on failedSubmit

    const {form, children, displayText, events} = props

    const [isForgotPasswordVisible, setIsForgotPasswordVisible] = useState(false)

    const forgotPasswordName = displayText.forgotPasswordName || "Forgot Password"

    const handleForgotPasswordClicked = () => {
        setIsForgotPasswordVisible(!isForgotPasswordVisible)
    }

    const reRender = () => setRefreshKey(new Date().getTime().toString())

    const eventsProps = {
        ...formPropsEvents(events),
        onAfterFailedSubmit: (e) => {
            formPropsEvents(events).onAfterFailedSubmit(e)
            reRender()
        }
    }

    useEffect(() => {
        // Re-render at least every 10 minutes to ensure failed login displayText property is updated in accordance with the session storage data
        setTimeout(() => {
            reRender()
        }, loginCheckExpiryTimeInMs + 1000)
    }, [refreshKey])
    
    useEffect(() => {
        if(email && formRef && formRef.current) {
            formRef.current.setFormData({ email })
        }
    }, [])
    

    return (
        <>
            {!isForgotPasswordVisible &&
            <MemberSignInFormComponent
                config={formPropsConfig(form)}
                displayText={formPropsDisplayText(form)}
                events={eventsProps}
                key={refreshKey}
                ref={formRef}
            >
                
                <MemberSignInFormFieldComponent name={Name.Identifier} displayText={formFieldsDisplayText(form).Email} />
                <MemberSignInFormFieldComponent name={Name.Password} displayText={formFieldsDisplayText(form).Password} />
                <MemberSignInFormFieldComponent name={Name.Remember} />
                
                <div className="v-field v-member-sign-in-form-field v-member-sign-in-form-field--forgot-password">
                    <p className="forgot-password" onClick={handleForgotPasswordClicked}>
                        {forgotPasswordName}
                    </p>
                </div>

                <LoadCaptcha>
                    <MemberSignInFormFieldComponent name={Name.Captcha}  />
                </LoadCaptcha>
                
                {children}
            </MemberSignInFormComponent>}

            {isForgotPasswordVisible && <>
                <SignInFormFieldForgotPassword>
                    <p className="go-back" onClick={handleForgotPasswordClicked}>
                        Go back to sign in
                    </p>
                </SignInFormFieldForgotPassword>
                {children}
            </>
            }
        </>
    )
}