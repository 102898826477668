import React from 'react'
import {
    ThreeDGlassesPickerComponent as OccThreeDGlassesPickerComponent,
} from '@vista/omnichannel-components-ui'
import { threeDGlassesPickerProps } from './props'
import { ThreeDGlassesPickerDetailsPropsFromBe } from './../../types'

export const ThreeDGlassesPickerComponent: React.FC<ThreeDGlassesPickerDetailsPropsFromBe> = props => {
    return (
        <>
            <OccThreeDGlassesPickerComponent {...threeDGlassesPickerProps(props)} />
        </>
    )
}
