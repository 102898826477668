import { SearchRes } from './../types'

export function getSearchRes(value: string, minSearchLength: number): Promise<SearchRes> {
    const url = new URL('/api/search/getresults/', baseUrl())
    url.searchParams.append('searchterm', value)

    const callApi = async () => {
        if (valueNotLongEnough(value, minSearchLength)) return emptyResponse()

        const res = await fetch(url.href, options())
        const json: SearchRes = await res.json()
        
        return json
    }

    return callApi()
}

const options = () => ({ method: 'get' })
const valueNotLongEnough = (value: string, minSearchLength: number) => value.length < minSearchLength
const emptyResponse = (): SearchRes => ({
    noResultsMessage: '',
    results: [],
})
const baseUrl = (): string => window.location.origin 

