import { getScreeningDatesForQuery, loadScreeningDatesByQuery } from '@vista/omnichannel-components-domain'

import { FilmScreening } from '@vista-digital/ocapi-types/v1'

export interface GetSitesFilmIsPlayingProps {
    filmIds: string[]
    attributeIds?: string[]
}

export async function getSitesFilmIsPlaying(props: GetSitesFilmIsPlayingProps): Promise<string[]> {
    const { filmIds, attributeIds } = props
    try {
        await loadScreeningDatesByQuery({
            filmIds: filmIds,
            siteIds: undefined,
        })

        const showtimes = getScreeningDatesForQuery({
            filmIds: filmIds,
            siteIds: undefined,
        })

        const filmScreenings: FilmScreening[] = showtimes
            .map(filmScreeningDate => filmScreeningDate.filmScreenings)
            .reduce((acc, val) => acc.concat(val), [])

        const filmSites = filmScreenings.map(fs => fs.sites).reduce((acc, val) => acc.concat(val), [])

        const filmSitesFilteredByAttributes = filmSites.filter(fs =>
            attributeIds && attributeIds.length ? attributeIds.some(aId => fs.showtimeAttributeIds.includes(aId)) : true
        )

        const distinctSiteIds = filmSitesFilteredByAttributes
            .map(s => s.siteId)
            .filter((value, index, self) => self.indexOf(value) === index)

        return distinctSiteIds
    } catch {
        return undefined
    }
}
