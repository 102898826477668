import React from 'react'
import { BookingStepperStepProps, BookingStepperStepStateProps } from '../../types'
import { BookingStepperStepBemClassNames } from './classNamesGenerator'

export const BookingStep: React.FC<BookingStepperStepProps> = props => {
    const cls = BookingStepperStepBemClassNames(props)
    return (
        <div className={cls.element}>
            <LineSvg className={cls.lineLeft + ' left'} />
            <CircleSvg className={cls.circle + ' center'} {...props.state} />
            <LineSvg className={cls.lineRight + ' right'} />
            <div className={cls.name}>
                <span>{props.name}</span>
            </div>
        </div>
    )
}

const LineSvg: React.FC<{ className: string }> = props => {
    return (
        <svg className={props.className} viewBox="0 0 100 4" xmlns="http://www.w3.org/2000/svg">
            <line x1="0" y1="2" x2="100" y2="2" />
        </svg>
    )
}

const CircleSvg: React.FC<BookingStepperStepStateProps & { className: string }> = props => {
    return (
        <svg className={props.className} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="50" />
            {props.complete && (
                <path
                    d="M30 50
                        45 70
                        75 30"
                    stroke="white"
                    strokeWidth="10"
                />
            )}
        </svg>
    )
}
