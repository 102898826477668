export enum MemberAccountMenuPages {
    Overview = "Overview",
    MyBookings = "MyBookings",
    Watchlist = "Watchlist",
    Rewards = "Rewards",
    Membership = "Membership",
    CardWalletWrap = "CardWalletWrap",
    Details = "Details",
    Preferences = "Preferences",
    CardWallet = "CardWallet",
    ComunicationPreferences = "ComunicationPreferences",
    SignOut = "SignOut",
}

export interface MemberAccountMenuPropsFromBe {
    displayText: MemberAccountMenuDisplayText
}

export interface MemberAccountMenuDisplayText {
    menuItems: Link[]
    signOutLabel: string
}

export interface Link {
    href: string
    text: string
    openInNewTab?: boolean
}


