import React, { forwardRef } from 'react'
import {
    TicketPickerComponent as OccTicketPickerComponent,
    TicketPickerHandles,
} from '@vista/omnichannel-components-ui'
import { ticketPickerProps } from './props'
import { TicketPickerPropsFromBe } from './../../types'

export const TicketPickerComponent = forwardRef<TicketPickerHandles, TicketPickerPropsFromBe>((props, ref) => {
    return (
        <>
            <div className="width--normal padding--add">
                <OccTicketPickerComponent ref={ref} {...ticketPickerProps(props)} />
            </div>
        </>
    )
})
