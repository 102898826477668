import { OccContextComponent } from '@vista/omnichannel-components-ui'
import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { InitReactComponent } from '../initialise/types'

/**
 * Initialise react component with props in element by ID
 * @example
 * initReactComponent<PropsType>({
 *     Component: props => (<ReactComponent {...props} />),
 *     id: 'html-id-selector',
 *     props: {},
 * })
 */
export function initReactComponent<PropsType>({ id, Component, props }: InitReactComponent<PropsType>): void {
    const element = document.getElementById(id)
    if (!element) return
    const occConfig = window.occContextConfig
    const reactElement = React.createElement(Component, props)
    const root = createRoot(element as HTMLElement)
    const wrapper = (
        <OccContextComponent {...occConfig}>
            <Suspense>{reactElement}</Suspense>
        </OccContextComponent>
    )

    root.render(wrapper)
}
