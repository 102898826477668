import { browsingDomainStore, getAllSites, getSiteById as getSiteByIdOCC, loadAllSites } from '@vista/omnichannel-components-domain'

import { EventShowtimePickerDropdownItemType } from '.'
import { Site } from '@vista-digital/ocapi-types/v1'

export async function getSites(): Promise<Array<EventShowtimePickerDropdownItemType>> {
    await loadAllSites()
    
    const sites = getAllSites()

    const siteList = sites.map((e) => {
        return {
            value: e.id,
            text: e.name.text
        }
    })

    return siteList
}

export async function getSiteById(id: string): Promise<Site> {
    await loadAllSites()

    return getSiteByIdOCC(id)
}

export function getSelectedSites(): string[] {
    return browsingDomainStore.data.selectedSiteIds
}
