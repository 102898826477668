import {
    ShowtimeContextComponent,
    ShowtimeContextDisplayText,
    ShowtimeContextProps,
} from '@vista/omnichannel-components-ui'

import { IShowtimeContextProvider } from '../types'
import React from 'react'

export const ShowtimeContextProvider: React.FC<IShowtimeContextProvider> = props => {
    
    const _props: ShowtimeContextProps = {
        showtimeId: props.workflow.ticketingWorkflow.showtimeId,
        displayText: displayTextWithDefaults(props),
        events: {},
        assets: {
            futureAdvanceBookingPeriodIconName: 'ticket-with-cross-eyes',
            failedToLoadDataIconName: 'ticket-with-cross-eyes',
        },
        children: props.children,
    }
  
    return <ShowtimeContextComponent {..._props}></ShowtimeContextComponent>
}

function displayTextWithDefaults(props: React.PropsWithChildren<IShowtimeContextProvider>): ShowtimeContextDisplayText {
    const defaultDisplayText = {
        futureAdvanceBookingPeriodTitle: 'Showtime not yet available',
        futureAdvanceBookingPeriodMessage:
            'This is an advance booking showtime. Tickets on sale from ${bookingPeriodStartsAt|d|Do of MMMM, h:mm A}.',
        futureAdvanceBookingPeriodTodayMessage:
            'This is an advance booking showtime. Tickets on sale today from ${bookingPeriodStartsAt|d|h:mm A}.',
        failedToLoadDataTitle: 'Showtime unavailable',
        failedToLoadDataMessage: "We can't access this showtime at the moment. Please try again soon.",
        messageActionButtonLabel: 'Return to home',
    }

    return {
        ...defaultDisplayText,
        ...props.displayText,
    }
}
