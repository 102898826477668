import { loadMember } from '.'

export class OpenLoginModal {
    private static openFunction: () => void

    static register(func: () => void): void {
        this.openFunction = func
    }

    static open(): void {
        this.openFunction()
    }

    static async openAsync(): Promise<void> {
        const member = await loadMember()
        if (!member) this.openFunction()
    }
}
