import {
    ModalSitePickerDisplayText,
    ModalSitePickerPropsType,
    defaultModalSitePickerDisplayText,
} from '../../types/modalSitePicker'

import { FilteredSitePickerComponent } from './FilteredSitePicker'
import { Modal } from '@vista/omnichannel-components-ui'
import React from 'react'
import { defaultSitePickerAssets } from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/types/assets'

export const ModalSitePickerComponent: React.FC<ModalSitePickerPropsType> = props => {
    const isOpen = props.show

    const displayText: ModalSitePickerDisplayText = {
        ...defaultModalSitePickerDisplayText,
        ...props.displayText,
    }
    const onClickHandler = () => {
        if (props.events && props.events.onClose) {
            props.events.onClose()
        }
    }

    const onSitesChangedHandler = () => {
        if (props.events && props.events.onSitesChanged) {
            props.events.onSitesChanged()
        }
    }

    const assets = {
        ...defaultSitePickerAssets,
        removeSelectedSiteIconName: 'c-icon_cross-black',
    }

    const classNameString = props.className ? props.className : ''

    const isOpenClass = isOpen ? 'isOpen' : 'isClosed'

    const className = `modal__site-picker ${classNameString} ${isOpenClass}`

    return (
        <>
            {isOpen && <div className="invisible-close" onClick={onClickHandler} />}
            <Modal
                show={isOpen}
                onClose={onClickHandler}
                className={className}
                heading={displayText.headerText}
                closeButtonIconName="cross"
            >
                <FilteredSitePickerComponent
                    displayText={props.sitePickerComponentProps.displayText}
                    assets={assets}
                    siteIds={props.siteIds}
                    onSitesChanged={onSitesChangedHandler}
                />
                <div className="site-picker__footer">
                    <button className="site-picker__footer-close" onClick={onClickHandler}>
                        {displayText.closeModalButtonText}
                    </button>
                </div>
            </Modal>
        </>
    )
}
