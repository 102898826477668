import { BookingStep } from './Step'
import React from 'react'
import { WorkflowNavigationPropsFromBe } from '../../types'
import { createSteps } from './props'
import { useShowtimeContext } from '@vista/omnichannel-components-ui'


export const BookingStepperComponent: React.FC<WorkflowNavigationPropsFromBe> = props => {
    const { showtime } = useShowtimeContext();
    const isUnallocated = !showtime.isAllocatedSeating
    const steps = createSteps(props, isUnallocated)
    const propsCls =  props.className ? ' ' + props.className : '' 
    const blockCls = 'booking-stepper'
    return (
        <div className={blockCls + propsCls}>
            {steps.map(page => {
                return (
                    <BookingStep {...page} blockCls={blockCls} key={page.uuid} />
                )
            })}
        </div>
    )
}
