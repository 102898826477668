import { MemberAccSidebarProps, Link } from '../../types'
import React, { FC, useContext, useEffect, useState } from 'react'
import { getMember, getMemberSubscriptions, getPrimaryMemberBalance, getSubscriptionById } from '@vista/omnichannel-components-domain'
import { isActiveLink, loadMember, signOutMemberAndGoHome } from '../../utils'

import { MemberAccContext } from '../../context'
import { MemberBalanceComponent, MemberContextComponent, MemberViewCardButtonProps } from '@vista/omnichannel-components-ui/'
import { MemberNameComponent } from '@vista/omnichannel-components-ui'
import { MemberPhotoComponent } from '@vista/omnichannel-components-ui/lib/src/lib/components/member/photo/member-photo'
import { MemberViewCardButtonComponent as OccMemberViewCardButtonComponent } from '@vista/omnichannel-components-ui/lib/src/lib/components/member/view-card-button/member-view-card-button'
import { memberViewCardButtonProps } from './props'
import { observer } from 'mobx-react'
import { useSubscriptionBackground } from './hooks/useSubscriptionBG'

export const MemberAccSidebarComponent: FC<MemberAccSidebarProps> = props => {
    const ctx = useContext(MemberAccContext)
    return (
        <div className={props.className ?? ''}>
            <aside className="memberacc__side__card-info">
                <UserInfo />
            </aside>
            <Menu className="memberacc__side__nav" />
        </div>
    )
}

export const UserInfo = observer(() => {
    const { MemberPointsBalance, MemberViewCardButton } = useContext(MemberAccContext)      
    const balance = getPrimaryMemberBalance(); 

    let displayText      

    function updatedPointsBalance() {
        if(!balance) return;
        //const currentValue = balance.availableUnits.toString()
        //const loyaltyBalance = memberBalanceText.replace("${memberBalance}", currentValue) - memberBalanceText no longer exists on this component, but keeping the logic around for a while longer
        
        displayText = {
            displayText:{...MemberPointsBalance.displayText, /*memberBalanceText: loyaltyBalance*/}
        }    

        
        
        const returnProps =  {...MemberPointsBalance, ...displayText}

        delete returnProps.displayText.pointsExpiryDetailsLabel
        delete returnProps.displayText.memberBalanceText
        delete returnProps.workflow

        return returnProps
    }

    return (
        <MemberContextComponent>
            <MemberPhotoComponent />
            <MemberNameComponent />
            <MemberBalanceComponent {...updatedPointsBalance()} />
            <MemberViewCardButtonComponent {...memberViewCardButtonProps(MemberViewCardButton)} />
            <MemberCardTypeComponent />
        </MemberContextComponent>
    )
})

const MemberViewCardButtonComponent: FC<MemberViewCardButtonProps> = (props) => {
    const { currentBackground } = useSubscriptionBackground()

    

    return (<>
        <OccMemberViewCardButtonComponent { ...props} />
        <style>
            {`
                .v-member-card .v-member-card__upper-section {
                    background-color:${currentBackground};
                }
            `}
        </style>
    </>)
}

export const MemberCardTypeComponent = () => {
    const [subscriptionName, setSubscriptionName] = useState<string>(undefined)
    
    useEffect(() => {
        (async () => {
            const member = await loadMember()
            if (!member?.subscriptions?.current) return;

            const subscription = getSubscriptionById(member.subscriptions.current.subscriptionId)
            setSubscriptionName(subscription.name.text)
        })()
    }, [])

    if(!subscriptionName) return null
    
    return (
        <div className="memberacc__side__card-info--white-box">
            <div className="v-detail v-member-type">
                <h3 className="v-detail__heading">Member Type</h3>
                <div className="v-detail__content">{subscriptionName}</div>
            </div>
        </div>
    )   
}

type MenuProps = {
    className?: string
}

export const Menu: FC<MenuProps> = ({className = ''}) => {
    const memberAccountProps = useContext(MemberAccContext)

    const { menuItems, signOutLabel } = memberAccountProps.MemberAccountMenu.displayText

    const signOut = (event) => {
        event.preventDefault()
        signOutMemberAndGoHome()
    }

    return (
        <nav className={className}>
            <ul className={`${className}__menu`}>
                { menuItems.map((link, i) => <MenuLink key={i} className={className} {...link} />) }
                <MenuLink key={menuItems.length} text={signOutLabel ?? 'Sign Out'} className={className} onClick={signOut} />
            </ul>
        </nav>
    )
}



type MenuLinkProps = Omit<Link,'href'> & {
    href?: string,
    className: string,
    onClick?: (event) => void
}

export const MenuLink: FC<MenuLinkProps> = ({text, href, className, onClick}) => {    
    const itemClassName = `${className}__menu-item ${isActiveLink(href)? 'active':'' }`

    return (
        <li className={itemClassName}>
            <a className={`${className}__menu-link`} href={href} onClick={onClick}>
                <span>{text}</span><svg className="c-icon c-icon__chevron-right"><use xlinkHref="#c-icon_chevron-right"></use></svg>
            </a>
        </li>
    )
}