import { QuantityLimit, QuantityLimitAdapter } from '../types'

/**
* Adapter to transform
* single BE interface to
* one of two optional OCC interfaces
*/
export function quantityLimitAdapter(limit: QuantityLimit): QuantityLimitAdapter {
    return limit.isUnlimited ? {
        isUnlimited: limit.isUnlimited,
        minimum: limit.minimum,
    } : {
        isUnlimited:limit.isUnlimited,
        maximum: limit.maximum,
        minimum: limit.minimum,
    }
}
