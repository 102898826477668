import { stopSwiper } from '../swiperFacade'
import { SwiperOptions } from 'swiper'
import { slideBreakPoints } from './slideBreakPoints'

export const fourColSettings = (items: number): SwiperOptions => {
    const options = {
        loop: false,
        centeredSlides: false,
        slidesPerView: items === 1 ? 1 : 1.2,
        spaceBetween: 16,
        breakpoints: {
            ...slideBreakPoints(items)
        },
    }

    if (items <= 4) return stopSwiper(options)

    return options
}