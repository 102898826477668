import { WorkflowComponent } from './propsFromBe'

import { ITicketingCustomLabelsDisplayText } from '.'

export interface ISignInFormPropsFromBe {
    SignInForm: ISignInForm
    ModalSignIn: IModalSignIn
    GuestSignIn: IGuestSignIn
}

export interface ISignInForm extends WorkflowComponent {
    displayText: ISignInFormDisplayText
    config: ISignInFormConfig
}

export interface ISignInFormDisplayText {
    fieldUsernameLabel: string
    fieldUsernamePlaceholder: string
    fieldEmailLabel: string
    fieldEmailPlaceholder: string
    fieldPasswordLabel: string
    fieldPasswordPlaceholder: string
    fieldRememberLabel: string
    buttonSubmitLabel: string
    errorUsernameRequiredMessage: string
    errorEmailRequiredMessage: string
    errorEmailFormatMessage: string
    errorPasswordRequiredMessage: string
    errorCaptchaRequiredMessage: string
    errorApiIncorrectSignInCredentialsMessage: string
    errorApiFailedSignInMessage: string
    errorApiMemberLockedMessage: string
}

export interface ISignInFormConfig {
    mode: 'EmailPassword'
}

export interface IModalSignIn extends WorkflowComponent {
    displayText: ISignInDisplayText
    config: IModalSignInConfig
}
export interface ISignInDisplayText {
    signInTitle: string
    signInText: string
    forgotPasswordName: string
}
export interface IModalSignInConfig {
    forgotPasswordUrl: string
    forgotPasswordTarget: string
}

export interface IGuestSignIn extends WorkflowComponent {
    displayText: IGuestSignInDispplayText
    config: IGuestSignInConfig
}
export interface IGuestSignInDispplayText {
    bookingFeeText: string
    continueAsGuestCTAText: string
    guestSignInText: string
    guestSignInTitle: string
    joinNowCTAText: string
    modalTitle: string
    modalText: string
    modalCancelButtonLabel: string
    modalGoToMembershipLabel:string
}

export interface IGuestSignInConfig {
    joinNowCTALink: string
    joinNowCTATarget: string
}

export interface ISignInEvents {
    signInHandler?: () => Promise<void> | void
    closeHandler?: VoidFunction
}

export interface ISignInBtn {
    show: boolean
    events: ISignInEvents
    modal: IModalSignIn
    form: ISignInForm
    guest: IGuestSignIn
}

export interface ISignIn extends Pick<ISignInBtn, 'form'> {
    displayText: Pick<ISignInDisplayText, 'forgotPasswordName'>,
    events: Pick<ISignInEvents, 'signInHandler'>
    children?: JSX.Element[] | JSX.Element
}

export enum SignInStatus {
    signedOut = 'SIGNOUT',
    signedIn = 'SIGNIN',
}

export interface TicketPickerSignInProps {
    children: React.ReactNode
    signInHandler: () => Promise<void> | void
    form: ISignInForm
    modal: IModalSignIn
    guest: IGuestSignIn
    customLabels: ITicketingCustomLabelsDisplayText
}
