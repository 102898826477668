import React, { useEffect, useState } from 'react'
import { Modal } from '@vista/omnichannel-components-ui'

type PopUpPropsBe = {
    id: string
    title: string
    link?: {
        name: string
        type: string
        target: string
        url: string
    }
    description: string
    headerMedia?: {
        imageCropDetails?: {
            src?: string
        }
    }
}

export const PopUpComponent: React.FC<PopUpPropsBe> = props => {
    const showedPopUpId = `showed-popup-${props.id}`
    const showedPopup = sessionStorage.getItem(showedPopUpId) === 'true'
    const [show, setShow] = useState<boolean>(!showedPopup)

    useEffect(() => {
        sessionStorage.setItem(showedPopUpId, 'true')
    }, [])

    const imageSizes =
        props.headerMedia && props.headerMedia.imageCropDetails && props.headerMedia.imageCropDetails.src
            ? {
                  desktop: `${props.headerMedia.imageCropDetails.src}?width=700`,
                  tablet: `${props.headerMedia.imageCropDetails.src}?width=500`,
                  mobile: `${props.headerMedia.imageCropDetails.src}?width=383`,
              }
            : null

    return (
        <Modal className="popup" show={show} onClose={() => setShow(false)}>
            <div className="popup__inner">
                <div className="popup__inner__text">
                    <div className="popup__inner__text__title">{props.title}</div>
                    <div
                        className="popup__inner__text__description"
                        dangerouslySetInnerHTML={{
                            __html: props.description,
                        }}
                    ></div>
                    {props.link && (
                        <div>
                            <a className="popup__inner__text__cta" href={props.link.url} target={props.link.target}>
                                <span className="popup__inner__text__cta__label">{props.link.name}</span>
                                <span>&#8594;</span>
                            </a>
                        </div>
                    )}
                </div>
                {imageSizes && (
                    <div className="popup__inner__image">
                        <picture>
                            {imageSizes.mobile && <source media="(max-width:425px)" srcSet={imageSizes.mobile} />}
                            {imageSizes.tablet && <source media="(max-width:768px)" srcSet={imageSizes.tablet} />}
                            <img src={imageSizes.desktop} alt="" />
                        </picture>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default PopUpComponent
