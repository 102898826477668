import { getAllEvents, getEventById, loadAllEvents, loadEventById } from '@vista/omnichannel-components-domain'

export interface EventShowtimePickerDropdownItemType {
    value: string
    text: string
}

export async function getEvents(): Promise<Array<EventShowtimePickerDropdownItemType>> {
    await loadAllEvents()

    const events = getAllEvents()

    const eventList = events.map(e => {
        return {
            value: e.id,
            text: e.name.text,
        }
    })

    return eventList
}

export async function getEventSiteIds(id: string): Promise<string[]> {
    await loadEventById(id)
    const event = getEventById(id)
    return event.siteIds
}
