import React from "react"

export interface ParagraphProps {
    baseClass?:string
    heading: string | React.ReactNode
    isLeftAligned?:boolean
}

export const Paragraph = (props:ParagraphProps) => {
    const {baseClass, heading, isLeftAligned} = props
    const b = baseClass && baseClass.length ? baseClass + "__" : ''
    const alignmentClass = isLeftAligned ? "left-aligned" : ""
    return (
        <div className={`${b}paragraph form-paragraph ${alignmentClass}`}>{heading}</div>
    )
}