import { Member } from '@vista-digital/ocapi-types/v1'
import React from 'react'
import { WorkflowComponent } from '../../types'
import { err } from '../../utils'

interface IMemberBtn extends WorkflowComponent {
    show: boolean
    member: Member
}

export const ButtonMember: React.FC<IMemberBtn> = props => {
    if (!props.show) return null

    const name = props.member?.personalDetails.name.givenName[0]?.toUpperCase()
    if (!name)
        err('Missing member givenName')

    const surname = props.member?.personalDetails.name.familyName[0]?.toUpperCase()
    if (!surname)
        err('Missing member familyName')

    const BtnContent = () => name && surname
        ? <span className="c-icon c-icon__user c-icon__round--pink">{name}{surname}</span>
        : <svg className="c-icon c-icon__user c-icon__round--pink"><use xlinkHref="#c-icon_user" /></svg>
    return (
        <>
            <button className="btn--icon main-nav__extra__user__status">
                <a href={props.workflow.browsingUrls.memberAccountOverviewUrl}><BtnContent /></a>
            </button>
        </>
    )
}
