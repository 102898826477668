import React from 'react'
import { LinkHref } from '../../types'
import cls from 'classnames'

export enum BtnColor {
    Black = 'Black',
    Grey = 'Grey',
    Pink = 'Pink',
    White = 'White',
    Orange = 'Orange',
    Txt = 'Txt',
}

/**
 * LinkHrefButton - button that is using LinkHref interface to create simple link-button.
 */
export const LinkHrefButton: React.FC<{ displayText: LinkHref; className?: string; color?: BtnColor }> = props => {
    const clsButton = cls({
        'btn--black': !props.color || props.color === BtnColor.Black, // default
        'btn--grey': props.color === BtnColor.Grey,
        'btn--pink': props.color === BtnColor.Pink,
        'btn--white': props.color === BtnColor.White,
        'btn--orange': props.color === BtnColor.Orange,
        'btn--txt': props.color === BtnColor.Txt,
    })

    const extraClass = () => {
        if (props.color) return ' ' + props.color
        return ''
    }

    return (
        <>
            <a
                className={clsButton + extraClass()}
                href={props.displayText.href}
                target={props.displayText.openInNewTab ? '_blank' : ''}
            >
                {props.displayText.text}
            </a>
        </>
    )
}
