import { Button, } from '@vista/omnichannel-components-ui'
import React, { useEffect, useState } from 'react'
import { SignIn } from '..'
import {ISignIn, ISignInDisplayText, ISignInForm} from '../../types'
import { isMemberSignInCultOrClassic, isSignedIn } from '../../utils'




export interface PreviewSignInDisplayText{

    title:string 
    paragraph:string 
    paragraphError: string 
    backLinkText: string 
    memberLinkName: string 
   
}

export interface PreviewSignInConfigProps {
    memberLinkUrl: string
    memberLinkTarget: string
}

export interface PreviewSignInEvents {
    backButtonClicked?: () => void
    onSignedIn?: () => void
}

export interface PreviewSignInProps {
    previewSignIn: PreviewSignInPropsFromBe
    form: ISignInForm 
    events: PreviewSignInEvents
    isHidden: boolean
    signIn:ISignInDisplayText
}



export interface PreviewSignInPropsFromBe {
    displayText: PreviewSignInDisplayText
    config: PreviewSignInConfigProps
}

export const PreviewSignIn: React.FC<PreviewSignInProps> = props => {
    const { previewSignIn, signIn, events, isHidden, form} = props


    const forgotPasswordName = signIn.forgotPasswordName
    const signInTitle = signIn.signInTitle
    const previewTitle = previewSignIn.displayText.title
    const previewParagraph = previewSignIn.displayText.paragraph
    const memberLink = {
        name: previewSignIn.displayText.memberLinkName,
        target: previewSignIn.config.memberLinkTarget,
        url: previewSignIn.config.memberLinkUrl 
    }
    const backLinkText = previewSignIn.displayText.backLinkText
    const previewParagraphError = previewSignIn.displayText.paragraphError


    const {backButtonClicked, onSignedIn} = events

    const [isMemberErrorVisible, setIsMemberErrorVisible] = useState(false)

    const onMemberButtonClicked = () => {
        if (memberLink.target === "_blank") {
            window.open(memberLink.url);
        }
        else {
            window.location.assign(memberLink.url || '')
        }
    }

    const onBackButtonClicked = () => {
        if(backButtonClicked) {
            backButtonClicked()
        }
    }
    
    const isHiddenClass = isHidden ? 'hidden' : ''

    useEffect(() => {
        // Check when you sign in if your membership is cult or classic
        if(isSignedIn()) {
            if(!isMemberSignInCultOrClassic()) {
                setIsMemberErrorVisible(true)
            }
        }
    }, [isSignedIn()])

    const signInProps:ISignIn = {
        events: {
            signInHandler: () => {
                if(isMemberSignInCultOrClassic()) {
                    if (onSignedIn) onSignedIn()
                }
            }
        },
        displayText: {
            forgotPasswordName: forgotPasswordName
        },
        form: form
    }

    return (
        <>
            <div className={`${isHiddenClass} preview-sign-in`}>
                    <div className='preview-message'>
                    <h3>{previewTitle}</h3>

                    {!isMemberErrorVisible && <div dangerouslySetInnerHTML={{__html:previewParagraph}}></div>}
                    {isMemberErrorVisible && <>
                        <div dangerouslySetInnerHTML={{__html:previewParagraphError}}></div>
                        <Button onClick={onMemberButtonClicked} className="btn--purple preview-btn-member" label={memberLink.name}></Button>
                    </>}
                </div>
                {!isMemberErrorVisible && 
                <div className="preview-signin">
                    <h3 className="member-signin-heading">{signInTitle}</h3>

                    <SignIn {...signInProps}>
                        <Button onClick={onMemberButtonClicked} className="btn--purple preview-btn-member" label={memberLink.name}></Button>
                        <Button onClick={onBackButtonClicked} className="btn--black preview-btn-back" label={backLinkText} ></Button>
                    </SignIn>
                </div>
                }
            </div>
            
        </>
    )
}

