import React, { forwardRef } from 'react'
import { SeatPickerPropsFromBe } from './../../types'
import {
    OrderSeatPickerComponent as OccOrderSeatPickerComponent,
    OrderSeatPickerHandles,
} from '@vista/omnichannel-components-ui'
import { orderSeatPickerProps } from './props'

export const SeatPickerComponent = forwardRef<OrderSeatPickerHandles, SeatPickerPropsFromBe>((props, ref) => {
    const cls = props.className ?? ''
    return (
        <div className={cls}>
            <OccOrderSeatPickerComponent ref={ref} {...orderSeatPickerProps(props)} />
        </div>
    )
})
