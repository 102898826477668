import {
    NotificationDetails,
    NotificationType,
    PartialExceptFor,
    addNotificationByType,
    removeNotification
} from '@vista/omnichannel-components-domain'

import { log } from '.'

export function addNotification(
    details: PartialExceptFor<NotificationDetails, 'id' | 'messages'>,
    type: NotificationType
): void {
    log('notification type: ', type)
    log('notification messages: ', details.messages)
    addNotificationByType(details, type)
}

export function removeNotifications(ids: string[]): void {
    ids.forEach(id => removeNotification(id))
}
