import React, { useEffect, useState } from 'react'
import {
    SitePickerMessage,
    SitePickerMessageType,
} from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/subcomponents/message'
import { SitePickerProps, SitePickerSkeleton, useI18n, useOccContext } from '@vista/omnichannel-components-ui'
import {
    addSelectedSite,
    configurationSettings,
    getAllSites,
    getSelectedSites,
    getSitesByIds,
    getUnselectedSites,
    hasMaximumSelectedSites,
    isNonEmptyArray,
    loadAllSites,
    logger,
    removeSelectedSite,
} from '@vista/omnichannel-components-domain'
import {
    defaultSitePickerProps,
    sitePickerPropsValidator,
} from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/types/props'

import { Site } from '@vista-digital/ocapi-types/v1'
import { SitePickerEmptyListMessage } from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/subcomponents/empty-list-message'
import { SitePickerSearchForm } from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/subcomponents/search-form'
import { SitePickerSelectedSiteList } from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/subcomponents/selected-site-list'
import { SitePickerUnselectedSiteList } from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/subcomponents/unselected-site-list'
import { Validator } from '@vista-digital/validator'
import { bemClassBuilder } from '@vista-digital/bem-class-builder'
import { nonEmptyStringValidator } from '@vista/omnichannel-components-domain'
import { observer } from 'mobx-react'
import { siteCompareFn } from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/utils/site-utils'
import { sitePickerAssetsValidator } from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/types/assets'
import {
    SitePickerDisplayText,
    sitePickerDisplayTextValidator,
} from '@vista/omnichannel-components-ui/lib/src/lib/components/site-picker/types/display-text'
import { useMountEffect } from '@vista-digital/react-tools'
import { withComponentWrapper } from '@vista/omnichannel-components-ui/lib/src/lib/utils/with-component-wrapper'

const b = bemClassBuilder('v-site-picker')

export interface SitePickerPropsWithSiteIds extends SitePickerProps {
    siteIds?: string[]
    onSitesChanged?: () => void
}

export const FilteredSitePicker: React.FC<SitePickerPropsWithSiteIds> = observer(props => {
    const { displayText, assets, siteIds } = props

    const getSites = () => (siteIds ? getSitesByIds(siteIds) : getAllSites())
    const allSites = getSites()
    const [filteredSites, setFilteredSites] = useState<Site[]>()
    const [searchTerm, setSearchTerm] = useState('')
    const [failedToLoadSites, setFailedToLoadSites] = useState(false)
    const {
        config: { enableSkeletonLoadingIndicators },
    } = useOccContext()

    const i18n = useI18n()

    useMountEffect(() => {
        ;(async () => {
            try {
                await loadAllSites()
                setFilteredSites(getSites())
            } catch (error) {
                setFailedToLoadSites(true)
                logger.error(`Failed to fetch sites. ${error}`)
            }
        })()
    })

    if (failedToLoadSites) {
        return (
            <SitePickerMessage
                messageType={SitePickerMessageType.FailedToLoadData}
                displayText={displayText}
                assets={assets}
            />
        )
    }

    if (!allSites || !filteredSites) {
        return enableSkeletonLoadingIndicators ? <SitePickerSkeleton /> : null
    }

    const unselectedSites = getUnselectedSites().sort((a, b) => {
        return siteCompareFn(a, b, i18n)
    })

    const handleSitesModified = () => {
        if (props.onSitesChanged) {
            props.onSitesChanged()
        }
    }

    const handleSelectedSiteClicked = (siteId: string) => {
        removeSelectedSite(siteId)
        handleSitesModified()
    }
    const handleUnselectedSiteClicked = (siteId: string) => {
        addSelectedSite(siteId)
        handleSitesModified()
    }

    const getFormattedSiteCount = (template: string, siteCount: number, maximumSelectedSites: number): string => {
        return i18n.template(template, { siteCount, maximumSelectedSites })
    }

    const getSitesToBeDisplayed = (
        unselectedSites: Site[],
        filteredSites?: Site[],
        filterSiteIds?: string[]
    ): Site[] => {
        if (!!filterSiteIds && filterSiteIds.length > 0)
            unselectedSites = unselectedSites.filter(s => filterSiteIds.includes(s.id))

        if (!filteredSites) return unselectedSites

        return unselectedSites.filter(unselectedSite =>
            isNonEmptyArray(filteredSites.filter(filteredSite => unselectedSite.id === filteredSite.id))
        )
    }

    const sitesToBeDisplayed = getSitesToBeDisplayed(unselectedSites, filteredSites, siteIds)
    const selectedSites = getSelectedSites().filter(({ id }) => allSites.map(site => site.id).includes(id))
    const { maximumSelectedSites } = configurationSettings.data.browsing
    const siteCountLabel =
        selectedSites.length < maximumSelectedSites
            ? getFormattedSiteCount(displayText.siteCountLabel, sitesToBeDisplayed.length, maximumSelectedSites)
            : getFormattedSiteCount(
                  displayText.siteCountLabelMaximumSelected,
                  sitesToBeDisplayed.length,
                  maximumSelectedSites
              )
    const allSitesSelected = selectedSites.length === Math.min(maximumSelectedSites, allSites.length)

    return (
        <div className={b.block()}>
            <div className={b.element('wrapper')}>
                <SitePickerSearchForm
                    sites={allSites}
                    displayText={displayText}
                    onResultsChanged={setFilteredSites}
                    onSearchTermChanged={setSearchTerm}
                />
                <SitePickerSelectedSiteList
                    sites={selectedSites}
                    onSiteClicked={handleSelectedSiteClicked}
                    removeSelectedSiteIconName={assets.removeSelectedSiteIconName}
                />
                {(sitesToBeDisplayed.length > 0 || allSitesSelected) && (
                    <span className={b.element('site-count')}>{siteCountLabel}</span>
                )}
            </div>
            {sitesToBeDisplayed.length > 0 ? (
                <SitePickerUnselectedSiteList
                    sites={sitesToBeDisplayed}
                    disabled={hasMaximumSelectedSites()}
                    onSiteClicked={handleUnselectedSiteClicked}
                />
            ) : (
                !allSitesSelected && (
                    <SitePickerEmptyListMessage searchTerm={searchTerm} displayText={displayText} assets={assets} />
                )
            )}
        </div>
    )
})
FilteredSitePicker.displayName = 'FilteredSitePicker'

export const customSitePickerPropsValidator = Validator.object<SitePickerPropsWithSiteIds>({
    siteIds: Validator.optional(Validator.array({ items: nonEmptyStringValidator })),
    onSitesChanged: Validator.optional(Validator.fn()),
    displayText: sitePickerDisplayTextValidator,
    assets: sitePickerAssetsValidator,
})

/**
 * The **Site Picker** component allows the user to select which cinema locations they would like to browse.
 */
export const FilteredSitePickerComponent = withComponentWrapper(
    FilteredSitePicker,
    defaultSitePickerProps,
    customSitePickerPropsValidator
)
