import React, { useEffect, useState } from 'react'

interface LoadCaptchaProps {
    children?:any
}

export const LoadCaptcha = (props:LoadCaptchaProps) => {
    const [ready, setReady] = useState(false)

    useEffect(() => {
        console.log('grecaptcha', window['grecaptcha'])
        if (window['grecaptcha']) {
            window['grecaptcha'].ready(() => {
                setReady(true)
            })
        }
    }, [window['grecaptcha']])

    if (ready) return <>{props.children}</>
    else return null
}

export function resetCaptcha() {
    try {
        if (window['grecaptcha']) {
            window['grecaptcha'].reset()
        }
    } catch {}
}
