export function getQuery() {
    if (typeof window !== 'undefined') {
        return new URLSearchParams(window.location.search)
    }
    return new URLSearchParams()
}

export function getQueryStringVal(key: string): string | null {
    return getQuery().get(key)
}

//able to capture non-alphanumeric characters
export function getQueryStringVal2(key: string): string {
    const value = window.location.search.replace(`?${key}=`, '')
    return decodeURIComponent(value)
}
