import { MemberCardWalletPropsFromBe } from '../../../types/memberAcc'
import { MemberPaymentCardWalletProps } from '@vista/omnichannel-components-ui'
import { NestedPartial } from '@vista/omnichannel-components-domain';

interface CardWalletOutput extends  NestedPartial<Omit<MemberPaymentCardWalletProps, 'paymentMethodId'>> {
    paymentMethodId: number
}

export function cardWalletProps(props: MemberCardWalletPropsFromBe):CardWalletOutput {

    const hardWrittenConfig = [
        {
            id: 'MasterCard',
            label: 'Mastercard',
            iconName: 'mastercard-logo'
        },
        {
            id: 'Maestro',
            label: 'Maestro',
            iconName: 'maestro-logo'
        },
        {
            id: 'Amex',
            label: 'AMEX',
            iconName: 'amex-logo'
        },
        {
            id: 'Visa',
            label: 'VISA',
            iconName: 'visa-logo'
        }
    ]

    const returnProps =  {
        paymentMethodId: 2,
        displayText: props.displayText,
        config: {
            ...props.config,
            cardTypes: hardWrittenConfig
        },
        assets: {
            failedToLoadDataIconName: 'curzon-warning',
            failedToInitialiseConnectorIconName: 'curzon-warning',
            addCardFailedIconName: 'curzon-card',
            cardDeclinedIconName: 'curzon-card',
            connectorLoadingIconName: 'spinner',
            addCardButtonIconName: 'plus-circle',
            removeCardButtonIconName: 'c-icon_trash',
            defaultCardIconName: 'credit-card',
            expiredCardIconName: 'credit-card-strike',
            processingAddCardIconName: 'spinner'
        },
    }

    delete returnProps.displayText.maskedCardLabel

    return returnProps
}
