import { AccordionElement, AccordionPropsFromBe } from '../../types'
import React, { useState } from 'react'

export const AccordionComponent: React.FC<AccordionPropsFromBe> = props => {
    const Title = () => !!props.displayText.title && <h2 className="title">{props.displayText.title}</h2>
    return (
        <>
            <div className="accordion-list">
                <Title />
                {props.displayText.elements.map(el => (
                    <ListElement {...el} key={el.id} />
                ))}
            </div>
        </>
    )
}

export const ListElement: React.FC<AccordionElement> = props => {

    const [isOpen, setIsOpen] = useState(false)
    const [bodyStyle, setBodyStyle] = useState<React.CSSProperties>()

    const openClass = isOpen ? 'open' : ''

    const clickHandler = (e:React.MouseEvent) => {

        const bodyTarget = e.currentTarget.parentElement.querySelector(".accordion-list__element-body")
        if (isOpen) {
            setIsOpen(false)
            setBodyStyle({maxHeight: '0px'})
        }
        else {
            setIsOpen(true)
            setBodyStyle({maxHeight: bodyTarget.scrollHeight + 'px'})
        }
    }

    return (
        <>
            <div className={`accordion-list__element ${openClass}`} data-accordion>
                <h5 className="accordion-list__element-title" data-accordion-title onClick={clickHandler}>
                    <span>{props.title}</span>
                    <svg className="c-icon c-icon__chevron-down">
                        <use xlinkHref="#c-icon_chevron-down"></use>
                    </svg>
                </h5>
                <div
                    className="accordion-list__element-body"
                    data-accordion-body
                    dangerouslySetInnerHTML={{ __html: props.bodyHtml }}
                    style={bodyStyle}
                />
            </div>
        </>
    )
}
