import { UrlFactory, removeLocalStorage } from './../../utils'

import { WorkflowData } from '../../initialise/types'

interface GotoNextPageProps {
    workflow: WorkflowData, 
    id?: string, 
    skipPages?:number,
    packageName?:string
}

export function goToNextPage(props:GotoNextPageProps): void {
    const { workflow, id, packageName} = props

    const skipPages = props.skipPages || 0
    const urlFactory = new UrlFactory(workflow)
    const nextIndex = workflow[workflow.workflowType].currentIndex + 1 + skipPages
    const nextPageUrl = urlFactory.getCurrentWorkflowUrl(nextIndex, id, packageName)
    window.location.assign(nextPageUrl)
}

export function goToPrevPage(workflow: WorkflowData, skip?: number): void {
    const currentIndex = workflow[workflow.workflowType].currentIndex
    const urlFactory = new UrlFactory(workflow)

    if (!skip &&(currentIndex === undefined || currentIndex === 0)) goHome()
    else goBack(currentIndex - 1 - (skip ? skip : 0), urlFactory)
}

export function goHome(): void {
    window.location.assign('/')
}

function goBack(prevIndex: number, urlFactory: UrlFactory) {
    window.location.assign(urlFactory.getCurrentWorkflowUrl(prevIndex))
}
