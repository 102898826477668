import { OrderPaymentFormProps } from '@vista/omnichannel-components-ui'
import { OrderPaymentFormPropsFromBe } from '../../types'

export function orderPaymentFormProps(props: OrderPaymentFormPropsFromBe): OrderPaymentFormProps {
    const returnProps = {
        displayText: displayText(props),
        config: config(props),
        //events: events(props),
        events: props.events,
        assets: assets(),
        cdnOptions: cdnOptions(),
    }

    /*@ts-ignore*/
    delete returnProps.config.displayErrorsInline

    return returnProps
}

function displayText(props: OrderPaymentFormPropsFromBe): OrderPaymentFormProps['displayText'] {
    const defaultDisplayText: OrderPaymentFormProps['displayText'] = {
        completeOrderButtonLabel: 'Complete Order',
        maskedGiftCardNumberFormat: 'Gift Card: ${maskedCardNumber}',
        failedToRemoveGiftCardErrorMessage: 'Failed to remove Gift card: ${maskedCardNumber}. Please try again.',
        costRemainingLabel: 'Remaining',
        paymentButtonLabel: 'Pay now',
        paymentMethodsHeading: 'Your payment options',
        paymentFailedTitle: 'Sorry, something went wrong.',
        paymentFailedMessage: 'Refresh your browser to try loading this payment method again.',
        orderCompletionFailedTitle: 'Sorry, something went wrong.',
        orderCompletionFailedMessage: 'Please contact cinema support for assistance.',
        failedToInitialiseConnectorTitle: 'Sorry, something went wrong.',
        failedToInitialiseConnectorMessage: 'Refresh your browser to try loading this payment method again.',
        paymentDeclinedTitle: 'Sorry, your payment was declined.',
        paymentDeclinedMessage:
            'Please check if you have sufficient funds to complete the payment or try a different payment method.',
        processingPaymentMessage: 'PROCESSING PAYMENT',
        paymentDeclinedTryAgainButtonLabel: 'Try again',
        failedToLoadDataTitle: 'Sorry, something went wrong.',
        failedToLoadDataMessage: 'Refresh your browser to try loading the payment form again.',
        memberBalancePaymentFormat: '${balanceTypeName}: ${units}',
        failedToRemoveMemberBalancePaymentErrorMessage:'Failed to remove payment of ${units}. Please try again.',
    }

    return {
        ...defaultDisplayText,
        ...props.displayText,
    }
}




function config(props: OrderPaymentFormPropsFromBe): OrderPaymentFormProps['config'] {
    return {
        redirectPaymentMethods: {
            showIcons: true,
            showNames: false,
        },
        ...props.config,
    }
}

function assets(): OrderPaymentFormProps['assets'] {
    return {
        orderCompletionFailedIconName: 'curzon-warning',
        paymentFailedIconName: 'curzon-warning',
        paymentDeclinedIconName: 'curzon-card',
        failedToInitialiseConnectorIconName: 'curzon-warning',
        removePaymentLoadingIconName: 'spinner',
        removePaymentButtonIconName: 'trash',
        failedToRemovePaymentIconName: 'curzon-warning',
        connectorLoadingIconName: 'spinner',
        processingPaymentIconName: 'spinner',
        failedToLoadDataIconName: 'curzon-warning',
    }
}

function cdnOptions(): OrderPaymentFormProps['cdnOptions'] {
    return {
        paymentMethodIcon: { width: 130 },
    }
}
