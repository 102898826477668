import {
    FormEvents,
    MemberPasswordResetCreateFormComponent,
    MemberPasswordResetCreateFormFieldComponent,
    MemberPasswordResetCreateFormFieldName,

} from '@vista/omnichannel-components-ui'

import { LoadCaptcha, addNotification, log, resetCaptcha } from '../../utils'
import React from 'react'

import { NotificationType } from '@vista/omnichannel-components-domain'


export interface SignInFormFieldForgotPasswordProps {
    children:JSX.Element[] | JSX.Element
}

export const SignInFormFieldForgotPassword: React.FC<SignInFormFieldForgotPasswordProps> = props => {
    const { children } = props

    const events: FormEvents = {
        onAfterSuccessfulSubmit: () => {
            addNotification(
                {
                    id: 'password-reset-notification',
                    messages: ['Email Sent, Check your inbox for instructions on how to reset your password'],
                },
                NotificationType.Success
            )
        },
        onAfterFailedSubmit: (e: Error) => {
            resetCaptcha()
            log('onAfterFailedSubmit', e)
        },
    }


    return (
        <>
            
            <div className="password-reset-container">
                <MemberPasswordResetCreateFormComponent events={events} >
                    <MemberPasswordResetCreateFormFieldComponent
                        name={MemberPasswordResetCreateFormFieldName.Identifier}
                        displayText={{
                            label: 'Email',
                            requiredMessage: `Email is required.`,
                            formatMessage: 'The email you entered has an invalid format.',
                        }}
                    />

                    {<LoadCaptcha>
                        <MemberPasswordResetCreateFormFieldComponent name={MemberPasswordResetCreateFormFieldName.Captcha}  />
                    </LoadCaptcha>}
                </MemberPasswordResetCreateFormComponent>
                {children}
            </div>
        </>
    )
}