import { SwiperCarousel } from './../types'
export const heroCarouselProps: SwiperCarousel = {
    config: {
        delay: 3000,
        className: '.hero__carousel',
    },
}

export const quoteCarouselProps: SwiperCarousel = {
    config: {
        delay: 3000,
        className: '.quote__carousel',
    },
}

export const threeColProps: SwiperCarousel = {
    config: {
        delay: 3000,
        className: '.three-col__carousel',
    },
}

export const threeColPanelProps: SwiperCarousel = {
    config: {
        delay: 3000,
        className: '.panels',
    },
}
