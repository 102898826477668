import { ComponentData, InitData, IsInWrapperComponents, WorkflowData, WrapperComponentsPropsType } from './types'
import { ComponentsBothWrapAndSingle, ReactSingleComponents, ReactWrapperComponentsNames } from '.'
import { group, groupEnd, initReactComponent } from '../utils'
import { log, warn } from '../utils'

export function dataComponents({componentsData, workflowData}: InitData): void {
    group('Components')
        runSingleComponents({componentsData, workflowData})
        runWrapperComponents({componentsData, workflowData})
    groupEnd()
}

function runSingleComponents({ componentsData, workflowData: workflow }: InitData) {
    componentsData.forEach(componentData => {
        const { componentType: componentName, id, props } = componentData
        try {
            // @TODO false positive 🐛
            // We need to be more specific about page components.
            // It will not throw if component exist on different page wrap object.
            // Only error handling is affected by that bug. 
            const isInAnyWrapperComponents = isInWrapperComponents({ wrapperComponents: ReactWrapperComponentsNames, name: componentName })
            if(isInAnyWrapperComponents && !ComponentsBothWrapAndSingle.includes(componentName)) 
                return 

            const Component = (props) => {
                const CurrentComponent = ReactSingleComponents[componentName]
                if(!CurrentComponent) {
                    console.error('👿<' + componentName + ' /> not exist.')
                    return null
                }
                
                return (
                        <CurrentComponent {...props}/>
                    )
            }
                
            props.workflow = workflow
            initReactComponent({ id, Component, props })
        } catch (e) {
            warn(e)
        }
        log(`🎁${componentName}(${id})  props: `, props)
    })
}

function runWrapperComponents({componentsData, workflowData: workflow}: InitData) {
    combineWrapperComponentsProps(componentsData, workflow).forEach(wrapperComponent => {
        const {id, props, component: Component} = wrapperComponent
        if(!document.getElementById(id))
            return

            const WrappedComponent = (props) => {
                return (
                        <Component {...props}/>
                    )
                }

        initReactComponent({ id, Component: WrappedComponent, props })
        log(`🎁🎁wrap(${id})  components: `, props)
    })
}

function combineWrapperComponentsProps(componentsData: ComponentData[], workflow: WorkflowData[]): WrapperComponentsPropsType[] {
    const wrapperComponents = ReactWrapperComponentsNames.map(wrapperComponent => {
        const { id, components, componentWrap } = wrapperComponent

        const props = {
            ...components.reduce((props, componentName) => {
                return {
                    ...props,
                    [componentName]: {
                        ...getComponentProps(componentsData, componentName),
                        workflow: workflow,
                    },
                }
            }, {})
        }

        const ComponentWrap = componentWrap
        const componentWrapWithContext = (props) => {
            return (
                        <ComponentWrap {...props}/>
            )
            
        }

        return { id, props, component: componentWrapWithContext }
    })
    return wrapperComponents
}

function getComponentProps(componentsData: ComponentData[], componentName: string) {
    const props = componentsData.find(component => component.componentType === componentName)?.props
    return props ?? {}
}

function isInWrapperComponents({name, wrapperComponents}: IsInWrapperComponents) {
    return  wrapperComponents.find(wrapper => wrapper.components.find(componentName => componentName === name))
}

