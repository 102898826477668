import { loadMember, MemberPreferencesToSend, updatePreferences, isDev } from '../../utils'
import { useContext, useEffect, useState } from 'react'

import { Button } from '@vista/omnichannel-components-ui'
import { MemberAccContext } from '../../context'
import { MemberCustomPreference, MemberContactMethod } from '@vista-digital/ocapi-types/v1'
import {Toggle, Paragraph, Heading, Subheading} from '../form' 
import { getMember } from '@vista/omnichannel-components-domain'

interface Preference extends MemberCustomPreference {
    index:number // Need a unique identifier
    isSelected:boolean
}

export const MemberAccPreferencesComponent: React.FC = () => {
    const ctx = useContext(MemberAccContext)

    const { displayText } = ctx.CommunicationPreferences

    

    const [preferenceData, setPreferenceData] = useState<Preference[]>(defaultCustomPreferences)
    const [isUnsubscribed, setIsUnsubscribed] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const togglePreference = (index:number) => {
        // If the id(s) are currently selected, then remove
        if(isSelected(index)) {
            updateIsSelected(index, false)
            if(!preferenceData.length) setIsUnsubscribed(true)
        }
        // Else update selected preferences and isUnsubscribed if needed
        else {
            if(isAllPreferencesUnselected()) {
                setIsUnsubscribed(false)
            }
            updateIsSelected(index, true)
        }
    }

    const isAllPreferencesUnselected = ():boolean => {
        return preferenceData.filter(p => !p.isSelected).length === preferenceData.length
    }

    const updateIsSelected = (index:number, isSelected:boolean) => {
        setPreferenceData([
            ...preferenceData.filter(pref => pref.index !== index), 
            {...getSelectedPreferenceByIndex(index), isSelected}
        ])
    }

    const getSelectedPreferenceByIndex = (index:number) => {
        return {...preferenceData.filter(pref => pref.index === index)[0]}
    }

    const updateCustomPreferencesIsSelected = (preferences:MemberCustomPreference[]) => {
        // If the incoming preference customPreferenceOptionIds exists, that means it is selected

        const newPreferences:Preference[] = [...defaultCustomPreferences]

        preferences.forEach(preference => {
            preference.customPreferenceOptionIds.forEach((optionId) => {
                const selectedOptionId = newPreferences.filter(newPref => { 
                    // OptionId and customPreferenceId match the data from the API, we can mark it as selected
                    return newPref.customPreferenceOptionIds.includes(optionId) && newPref.customPreferenceId === preference.customPreferenceId
                })[0]

                selectedOptionId.isSelected = selectedOptionId ? true : false
            })
        })

        setPreferenceData(newPreferences)
    }

    useEffect(() => {
        (async () => {
            await loadMember()

            const customPreferences:MemberCustomPreference[] = getMember().personalDetails.preferences.customPreferences

            if(customPreferences.length) {
                updateCustomPreferencesIsSelected(customPreferences)
            }
            else {
                setIsUnsubscribed(true)
            }
            
        })()
    }, [])

    const handleSubmission = async () => {

        const selectedPreferences = preferenceData.filter(p => p.isSelected)
        const newCustomPreferences: MemberCustomPreference[] = mergePreferences(selectedPreferences)

        const preferences: MemberPreferencesToSend = {
            contactMethods: isUnsubscribed || !newCustomPreferences.length ? [] : [MemberContactMethod.Email],
            customPreferences: isUnsubscribed || !preferenceData.length ? [] : newCustomPreferences
        }

        setIsLoading(true)

        await updatePreferences(preferences)

        setIsLoading(false)
    }

    const isChecked = (index:number):boolean => {
        return isSelected(index) && !isUnsubscribed
    }

    const isSelected = (index):boolean => {
        return getSelectedPreferenceByIndex(index).isSelected;
    }

    const baseClass = "member-preferences"

    return (
        <div className='member-preferences'>

            <Heading heading={displayText.title} baseClass="member-preferences" />
            <Paragraph heading="Get the latest news on films, events and more at Curzon, with out weekly newsletter." baseClass={baseClass} />
            

            <div className='member-preferences__form'>
                <Subheading heading="Newsletters" baseClass={baseClass} isLeftAligned={true} />

                <Toggle label="Curzon Home Cinema" isChecked={isChecked(0)} baseClass={baseClass} onClick={() => togglePreference(0)} isSwitchBeforeLabel={true} />
                <Toggle label="Curzon Cinemas" isChecked={isChecked(1)} baseClass={baseClass} onClick={() => togglePreference(1)} isSwitchBeforeLabel={true} />
                <Toggle label="Curzon Film" isChecked={isChecked(2)} baseClass={baseClass} onClick={() => togglePreference(2)} isSwitchBeforeLabel={true} />


                <Subheading heading="Personalised Recommendations" baseClass={baseClass} isLeftAligned={true} />

                <Toggle label="Curzon Home Cinema" isChecked={isChecked(3)} baseClass={baseClass} onClick={() => togglePreference(3)} isSwitchBeforeLabel={true} />
                <Toggle label="Curzon Cinemas" isChecked={isChecked(4)} baseClass={baseClass} onClick={() => togglePreference(4)} isSwitchBeforeLabel={true} />
                <Toggle label="Special Offers" isChecked={isChecked(5)} baseClass={baseClass} onClick={() => togglePreference(5)} isSwitchBeforeLabel={true} />
                

                <Subheading heading="Member Exclusive Communications" baseClass={baseClass} isLeftAligned={true} />

                <Toggle label="Member Exclusive Communications" isChecked={isChecked(6)} baseClass={baseClass} onClick={() => togglePreference(6)} isSwitchBeforeLabel={true} />

                <Subheading heading="Unsubscribe From All" baseClass={baseClass} isLeftAligned={true} />

                <Toggle label="Unsubscribe From All" isChecked={isUnsubscribed} baseClass={baseClass} onClick={() => setIsUnsubscribed(!isUnsubscribed)} isSwitchBeforeLabel={true} />

                <Button loading={isLoading} onClick={() => handleSubmission()}>{displayText.buttonText}</Button>
            </div>
        </div>
    )
}

export const mergePreferences = (selectedPreferences:Preference[]): MemberCustomPreference[] => {
    const newCustomPreferences: MemberCustomPreference[] = []

    // Loop through each customPreferenceId and merge all the customPreferenceOptionIds into a single entry

    selectedPreferences.forEach(preference => {
        // If customPreferenceId doesn't exist, create it
        const currentCustomPreferenceIdObject = newCustomPreferences.filter(p => p.customPreferenceId === preference.customPreferenceId)[0]
        if(!currentCustomPreferenceIdObject) {
            newCustomPreferences.push({customPreferenceId: preference.customPreferenceId, customPreferenceOptionIds: []})
        }

        // Add customPreferenceOptionIds to the object in the array with the correct customPreferenceId
        const newCurrentCustomPreferenceIdObject = newCustomPreferences.filter(p => p.customPreferenceId === preference.customPreferenceId)[0]
        preference.customPreferenceOptionIds.forEach(optionId => newCurrentCustomPreferenceIdObject.customPreferenceOptionIds.push(optionId))
    })

    return newCustomPreferences
}

// NOTE - do not have duplicate customPreferenceOptionIds. 
// If a customPreferenceOptionId comes back from the API, it will mark all custom preferences with that id as selected

export const defaultCustomPreferences:Preference[] = [
    // NEWSLETTER - Curzon Home Cinema
    {
        index:0,
        isSelected: false,
        customPreferenceId: 9,
        customPreferenceOptionIds: [32]
    },
    // NEWSLETTER - Curzon Cinemas
    {
        index: 1,
        isSelected: false,
        customPreferenceId: 9,
        customPreferenceOptionIds: [33]
    },
    // NEWSLETTER - Third Party
    {
        index: 2,
        isSelected: false,
        customPreferenceId: 9,
        customPreferenceOptionIds: [34]
    },
    // PERSONALISED RECOMMENDATIONS - Curzon Home Cinema
    {
        index: 3,
        isSelected: false,
        customPreferenceId: 9,
        customPreferenceOptionIds: [35]
    },
    // PERSONALISED RECOMMENDATIONS - Curzon Cinemas
    {
        index: 4,
        isSelected: false,
        customPreferenceId: 9,
        customPreferenceOptionIds: [36]
    },
    // PERSONALISED RECOMMENDATIONS - Curzon Film
    {
        index: 5,
        isSelected: false,
        customPreferenceId: 9,
        customPreferenceOptionIds: [37]
    },
    // MEMBER EXCLUSIVE COMMUNICATIONS
    {
        index: 6,
        isSelected: false,
        customPreferenceId: 9,
        customPreferenceOptionIds: isDev ? [41] : [38]
    }
]