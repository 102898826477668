import React, {ReactNode} from 'react'

export const Title: React.FC<{className?: string; hx?: 1|2|3|4|5|6; children: ReactNode}> = props => {
    const cls = props.className ?? ''
    const Title = () =>
            props.hx === 1 ? <h1 className={cls + " title"}>{props.children}</h1>
          : props.hx === 2 ? <h2 className={cls + " title"}>{props.children}</h2>
          : props.hx === 3 ? <h3 className={cls + " title"}>{props.children}</h3>
          : props.hx === 4 ? <h4 className={cls + " title"}>{props.children}</h4>
          : props.hx === 5 ? <h5 className={cls + " title"}>{props.children}</h5>
          : props.hx === 6 ? <h6 className={cls + " title"}>{props.children}</h6>
          : <h2 className={cls + " title"}>{props.children}</h2>

    return <Title />
}
