import React from "react"

export interface HeadingProps {
    heading: string | React.ReactNode
    baseClass?:string
    isLeftAligned?:boolean
}

export const Heading = (props:HeadingProps) => {
    const {baseClass, heading, isLeftAligned} = props
    const b = baseClass && baseClass.length ? baseClass + "__" : ''
    const alignmentClass = isLeftAligned ? "left-aligned" : ""

    return (
        <h3 className={`${b}heading form-heading ${alignmentClass}`}>{heading}</h3>
    )
}