import React, { useContext } from 'react'

import { OrderCartComponent as OccOrderCartComponent } from '@vista/omnichannel-components-ui'
import { OrderCartPropsFromBe } from '../../types'
import { orderCartProps } from './props'

export const OrderCartComponent: React.FC<OrderCartPropsFromBe> = props => {
    return (
            <OccOrderCartComponent {...orderCartProps(props)} />
    )
}
