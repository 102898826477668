/**
 * @returns {boolean} check sessionStorage for dev flag
 */
 export function isDev(): boolean {
    let isDev = false
    try {
        isDev = JSON.parse(sessionStorage.dev)
    } catch {
        isDev = false
    }
    return isDev
}
