import { isDev } from './isDev'

declare global {
    interface Window {
        dbg: any
    }
}

export function setDevMode(): void {
    if (isDev()) message()

    window.dbg = {
        on: setOn,
        off: setOff,
        toggle: setToggle,
        clean: setClean,
    }
}

function message(optionalMessage = '') {
    /* eslint-disable no-console */
    if (isDev())
        console.log(
            '%c debuging mode ON ' + optionalMessage,
            'background: green; color: white'
        )
    else
        console.log(
            '%c debuging mode OFF ' + optionalMessage,
            'background: red; color: white'
        )
    /* eslint-enable no-console */
}

function setOn() {
    window.sessionStorage.setItem('dev', 'true')
    message()
}
function setOff() {
    window.sessionStorage.setItem('dev', 'false')
    message()
}
function setToggle() {
    window.sessionStorage.setItem('dev', isDev() ? 'false' : 'true')
    message()
}
function setClean() {
    window.sessionStorage.removeItem('dev')
    message('(flag deleted from session storage)')
}
