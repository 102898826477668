import {
    MemberPasswordResetFormComponent,
    MemberPasswordResetFormEvents,
    MemberPasswordResetFormFieldComponent,
    MemberPasswordResetFormFieldName,
    MemberPasswordResetFormFieldProps,
    MemberPasswordResetFormProps,
} from '@vista/omnichannel-components-ui'
import { SessionStorageNotification, getQueryStringVal, setSessionStorage } from '../../utils'

import { NotificationType } from '@vista/omnichannel-components-domain'
import { NotificationListComponent as OccNotificationListComponent } from '@vista/omnichannel-components-ui'
import { PasswordRestFormPageProps } from './../../types/memberPasswordResetPage'
import React from 'react'

export const PasswordResetForm: React.FC<PasswordRestFormPageProps> = props => {
    console.log('PasswordRestFormPageProps', props)
    const resetCode = getQueryStringVal('resetCode')

    const formProps = (props: PasswordRestFormPageProps):Omit<MemberPasswordResetFormProps, 'assets' | 'config' | 'events' | 'children'> => {
        const returnProps =  {
            ...props.PasswordResetCreateForm,
            resetCode: resetCode,
            //children: MemberPasswordResetFormFieldComponent,
        }

        delete returnProps.workflow
        /*@ts-ignore*/
        delete returnProps.displayText.errorApiFailedMessage

        return returnProps
    }

    const fieldProps = (props: PasswordRestFormPageProps):MemberPasswordResetFormFieldProps => {
        const returnProps =  {
            ...props.PasswordResetCreateFormField,
            name: MemberPasswordResetFormFieldName.Password,
            //formId: 'example-password-reset-form',
        }

        delete returnProps.workflow
        delete returnProps.displayText.requiredMessage
        delete returnProps.displayText.formatMessage
        delete returnProps.displayText.lengthMessage

        return returnProps
    }

    const events: MemberPasswordResetFormEvents = {
        onAfterSuccessfulSubmit: () => {
            const notification: SessionStorageNotification = {
                details: {
                    id: 'password-reset-notification',
                    iconName: 'custom-tick-circle',
                    messages: ['Your password has been successfully reset'],
                },
                type: NotificationType.Success,
            }
            setSessionStorage(SessionStorageNotification.key, notification)

            window.location.assign('/')
        },

        onPasswordResetMessageActionButtonClick: () => {
            window.location.assign('/')
        },
    }

    return (
        <>
            <h1> Password Reset </h1>

            <MemberPasswordResetFormComponent {...formProps(props)} events={events}>
                <MemberPasswordResetFormFieldComponent {...fieldProps(props)} />
            </MemberPasswordResetFormComponent>

            <OccNotificationListComponent />
        </>
    )
}
