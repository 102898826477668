import { GiftCardCheckerComponent, Modal } from '@vista/omnichannel-components-ui'
import React, { useState } from 'react'

import { GiftShopHeaderPropsFromBe } from '../../types'

export const GiftShopHeaderComponent: React.FC<GiftShopHeaderPropsFromBe> = props => {
    const { giftShopCustomDetails, giftCardChecker } = props

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const onClickHandler = () => setIsOpen(!isOpen)

    const giftCardCheckerProps = {
        ...giftCardChecker
    }

    delete giftCardCheckerProps.displayText.errorInvalidGiftCardNumberOrPinMessage

    /*@ts-ignore*/
    delete giftCardCheckerProps.workflow

    return (
        <div className="gift-shop-header">
            <h2 className="gift-shop-header__title">{giftShopCustomDetails.displayText.subheading}</h2>
            <button onClick={onClickHandler} className="gift-shop-header__card-checker-button">
                Check Balance
            </button>

            <Modal
                show={isOpen}
                onClose={onClickHandler}
                className="modal__card-checker"
                heading={'Check Card Balance'}
                closeButtonIconName="cross"
            >
                <GiftCardCheckerComponent
                    {...giftCardCheckerProps}
                    config={{
                        validation: {
                            pin: {
                                required: false,
                            },
                        },
                    }}
                />
            </Modal>
        </div>
    )
}
